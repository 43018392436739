import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paniniLogo from '../images/logo_panini.png';
import comboLogo from '../images/COMBOLOGO.png';
import { Link } from 'react-router-dom';
import styles from './MinhaConta.module.css';
import Header from './HeaderCB2024';
import { getFirestore, query, where, collection, getDocs } from 'firebase/firestore'; // Adicionado importação para 'getFirestore'

const MinhaContaCB2024 = () => {
  let navigate = useNavigate();
  const [isAuthenticated, setisAuthenticated] = useState(() => localStorage.getItem('isAuthenticated') === 'true');
  const [key, setKey] = useState(0);
  const [couponMessage, setCouponMessage] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (window.gigya) {
      console.log("Gigya disponível");

      window.gigya.accounts.addEventHandlers({
        onLogin: (event) => {
          const userEmail = event.userEmail || event.email || (event.profile && event.profile.email);
          if (userEmail) {
            setisAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true');
            setKey(prevKey => prevKey + 1);
            setEmail(userEmail);
            console.log('E-mail do usuário:', userEmail);
          } else {
            console.log("Evento de login acionado, mas email não encontrado no objeto:", event);
          }
        },
        onError: (error) => {
          console.log("Erro ocorreu", error);
        }
      });

      if (isAuthenticated) {
        window.gigya.accounts.showScreenSet({
          screenSet: 'Store-ProfileUpdate',
          containerID: 'gigya-container',
          lang: 'pt-BR',
        });
      } else {
        window.gigya.accounts.showScreenSet({
          screenSet: 'Store-RegistrationLogin',
          startScreen: 'gigya-login-screen',
          containerID: 'gigya-container',
          lang: 'pt-BR',
        });
      }

     if (email) {
  console.log("Buscando dados do usuário para o email:", email);
  const db = getFirestore();
  const q = query(collection(db, 'participantes'), where('email', '==', email), where("fromCB2024", "==", true));

  getDocs(q).then((querySnapshot) => {
    console.log("Resultado da consulta:", querySnapshot);

    if (!querySnapshot.empty) {
      const userSnapshot = querySnapshot.docs[0];
      console.log("Dados do usuário:", userSnapshot.data()); // Log adicional para verificar os dados

      const userData = userSnapshot.data();
      const { couponCodeFromCB2024: coupon, passedCB2024, attemptCB2024 } = userData;


      if (coupon) {
        setCouponMessage(`Seu Cupom: ${coupon}`);
      } else if (!passedCB2024 && attemptCB2024 === 3) {
        setCouponMessage('Seu Cupom: Não Passou!');
      } else if (!passedCB2024 && attemptCB2024 <= 2) {
        setCouponMessage('Cupom: Passe no Quiz para receber seu Código');
      }
    } else {
      console.log("Documento do usuário não encontrado para o email:", email);
    }
  }).catch(error => {
    console.error("Ocorreu um erro ao buscar o email:", error);
  });
} else {
  console.log("Email não definido.");
}


    } else {
      console.log("Gigya não disponível");
    }
  }, [isAuthenticated, email]);

  return (
    <div>
      <Header key={key} />
      
       {couponMessage && <div className={styles.couponCard}><p>{couponMessage}</p></div>}



      
      <div className={styles.container}>
        <div id="gigya-container" className={styles.gigyaContainer}></div>
      </div>
      <div className="footer text-center">
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <footer className="footer">
          <div className="footer-links">
            <a className="footer-link" href="/cob/termos-e-condicoes">Termos e Condições</a>
            <span className="footer-link-separator">|</span>
            <a className="footer-link" href="/cob/politica-de-privacidade">Política de Privacidade</a>
          </div>
        </footer>
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  );
};

export default MinhaContaCB2024;