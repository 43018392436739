import React, { useEffect, useContext, useState } from 'react';
import { AuthContextCB2024 } from './AuthContextCB2024';
import { useNavigate, Link } from 'react-router-dom';
import SideNav from './SideNav'; // Importando o componente SideNav
import logoPanini from '../images/logo_panini.png'; // Importando a imagem do logo da Panini
import './Dashboard.css';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

function DashboardCB2024() {
  const { userCB2024 } = useContext(AuthContextCB2024);
  const navigate = useNavigate();

  const [participantCount, setParticipantCount] = useState(0);
  const [couponCount, setCouponCount] = useState(0);

  useEffect(() => {
    if (!userCB2024) {
      navigate('/cb2024/dsa_admin_login');
    }

    const fetchData = async () => {
      const participantsSnapshot = collection(db, "participantes");
      const q = query(participantsSnapshot, where("fromCB2024", "==", true));
      const snapshot = await getDocs(q);
      setParticipantCount(snapshot.size);

      const usedCouponsQuery = query(collection(db, "coupons"), where("used", "==", true), where("from", "==", "cb2024"));
      const usedCouponsSnapshot = await getDocs(usedCouponsQuery);
      setCouponCount(usedCouponsSnapshot.size);
    };

    fetchData();
  }, [userCB2024, navigate]);

  return (
    <div className="dashboard">
      <div className="header">
        <img src={logoPanini} alt="Logo Panini" className="logo" />
        
      </div>
      <div className="dashboard-content">
<h2 className="welcome-text">Seja bem-vindo ao painel de controle da Digital Sport Academy</h2>
        <div className="dashboard-left">
        <div className="side-nav">
      <ul>
        <li><Link to="/cb2024/dsa_admin/users">Usuários</Link></li>
        <li><Link to="/cb2024/dsa_admin/cupons">Gerenciador de Cupons</Link></li>
        <li><Link to="/cb2024/dsa_admin/questions">Editor de Questões</Link></li>
        <li><Link to="/cb2024/dsa_admin/exportar-participantes">Gerador de Relatórios</Link></li>

      </ul>
    </div>
        </div>
        <div className="dashboard-right">
          <p>Participantes do Quiz: {participantCount}</p>
          <p>Cupons Distribuídos: {couponCount}</p>
          <button onClick={() => navigate('/cb2024/dsa_admin_login')}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default DashboardCB2024;


