import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Inicio.css';
import paniniLogo from '../images/logo_panini.png';
import { getUserByEmail } from '../firebase'; 
import Header from './Header'; // Importe o componente de cabeçalho 


const Inicio = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const email = window.localStorage.getItem('userEmail');
  const age = window.localStorage.getItem('userAge');

  // Redirecionar para a rota de registro se o email não estiver disponível
  useEffect(() => {
    if (!email) {
      navigate('/cob/register');
    }
    if (age < 16) {
      navigate('/cob/register');
    }
  }, [email, navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUserByEmail(email);
      setUser(userData);
    };

    fetchUser();
  }, [email]);

  console.log('User in Inicio component:', user); 

  const startQuiz = () => {
    navigate('/cob/quiz');
  }

  return (
    <div className="inicio-container">
      <Header /> {/* Use o componente de cabeçalho */}
      <p className="welcome-text">Olá, {user?.displayName}</p>
      <h1>Seja Bem Vindo(a) ao Quiz</h1>
      <h2 className="subtitle">Digital Sport Academy</h2>
      <p className="info-paragraph">
        Este quiz é composto de 15 perguntas aleatórias, onde você terá 3 tentativas para realizá-lo.
      </p>
      <p className="info-paragraph">
        
O tempo de resposta para cada pergunta será de 30 segundos.
      </p>
<p className="info-paragraph">
        
Caso não o faça dentro deste tempo, a pergunta será considerada incorreta.
      </p>
      <button onClick={startQuiz} className="start-button">Iniciar Quiz</button>
    </div>
  );
}

export default Inicio;



