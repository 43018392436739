import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './TryAgain.module.css';
import logo from '../images/logo_panini.png';
import { QuizContextCB2024 } from './QuizContextCB2024';
import Header from './HeaderCB2024';

const TryAgainCB2024 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usercorrectAnswersCB2024 = location.state?.correctAnswersCB2024 || [];

  const { remainingattemptCB2024s } = useContext(QuizContextCB2024); // Obtendo remainingattemptCB2024s do contexto do Quiz

  const handleRestartQuiz = () => {
    navigate('/cb2024/quiz');
  };

  const handleExitQuiz = () => {
    navigate('/cb2024');
  };

  return (
<>
      <Header />
    <div className={styles['tryagain-container']}>
      <img src={logo} alt="Panini Logo" className={styles.logo}/>
      <div>
        <h1 className={styles.title}>Não Passou.</h1>
        <h2 className={styles.subtitle}>Tente novamente!</h2>
      </div>
      <p className={styles.text}>
        Parabéns por ter concluído o teste e obrigado por participar! <br/>
        <br/>
        Você respondeu corretamente a <span className={styles.redText}><strong>{usercorrectAnswersCB2024.filter(answer => answer).length}</strong></span> perguntas de 15.<br/><br/>
        Infelizmente, você não passou no teste e não pode acessar a "Digital Sport Academy" powered by Panini.<br/>
        <br/>
        Mas ainda não acabou... <br/>
        Você tem mais <span className={styles.redText}><strong>{remainingattemptCB2024s}</strong></span> {remainingattemptCB2024s > 1 ? 'tentativas' : 'tentativa'}.<br/>
        <br/>
        Boa sorte!<br/>
        Equipe Panini
      </p>
      <button className={styles.button} onClick={handleRestartQuiz}>Reiniciar Quiz</button>
      <button className={styles.exitButton} onClick={handleExitQuiz}>Sair</button>
    </div>
 </>
  );
};

export default TryAgainCB2024;