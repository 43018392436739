import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NotFound from './components/NotFound';
import GoogleAnalytics from './components/GoogleAnalytics'; // Import the new component

// Rotas COB
import { QuizProvider } from './components/QuizContext';
import { AuthProvider, AuthContext } from './components/AuthContext';
import Home from './components/Home';
import Register from './components/Register';
import Quiz from './components/Quiz';
import Congratulations from './components/Congratulations';
import TryAgain from './components/TryAgain';
import Dashboard from './components/Dashboard';
import UserList from './components/UserList';
import QuestionEditor from './components/QuestionEditor';
import CupomManager from './components/CupomManager';
import Login from './components/Login';
import Inicio from './components/Inicio';
import UserProfile from './components/UserProfile';
import NaoPassou from './components/NaoPassou';
import TermosECondicoes from './components/TermosECondicoes';
import PoliticaDePrivacidade from './components/PoliticaDePrivacidade';
import PoliticaDeCookies from './components/PoliticaDeCookies';
import MinhaConta from './components/MinhaConta';
import Regulamento from './components/Regulamento';
import ExportXLSX from './components/ExportXLSX';
// Rotas CB2024
import { AuthProviderCB2024, AuthContextCB2024 } from './components/AuthContextCB2024';
import HomeCB2024 from './components/HomeCB2024';
import NotFoundCB2024 from './components/NotFoundCB2024';
import RegisterCB2024 from './components/RegisterCB2024';
import QuizCB2024 from './components/QuizCB2024';
import InicioCB2024 from './components/InicioCB2024';
import CongratulationsCB2024 from './components/CongratulationsCB2024';
import TryAgainCB2024 from './components/TryAgainCB2024';
import LoginCB2024 from './components/LoginCB2024';
import DashboardCB2024 from './components/DashboardCB2024';
import UserListCB2024 from './components/UserListCB2024';
import CupomManagerCB2024 from './components/CupomManagerCB2024';
import UserProfileCB2024 from './components/UserProfileCB2024';
import NaoPassouCB2024 from './components/NaoPassouCB2024';
import QuestionEditorCB2024 from './components/QuestionEditorCB2024';
import TermosECondicoesCB2024 from './components/TermosECondicoesCB2024';
import PoliticaDePrivacidadeCB2024 from './components/PoliticaDePrivacidadeCB2024';
import PoliticaDeCookiesCB2024 from './components/PoliticaDeCookiesCB2024';
import RegulamentoCB2024 from './components/RegulamentoCB2024';
import MinhaContaCB2024 from './components/MinhaContaCB2024';
import { QuizProviderCB2024 } from './components/QuizContextCB2024';
import ExportXLSXCB2024 from './components/ExportXLSXCB2024';

function App() {
  const authContext = useContext(AuthContext);
  const user = authContext ? authContext.user : null;

  const authContextCB2024 = useContext(AuthContextCB2024);
  const userCB2024 = authContextCB2024 ? authContextCB2024.userCB2024 : null;

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/cob" replace />} />
          <Route path="*" element={<NotFound />} />

          {/* Rotas COB */}
          <Route path="/cob/*" element={
            <>
              <GoogleAnalytics trackingId="G-6EDVBBKDVQ" />
              <AuthProvider>
                <QuizProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="register" element={<Register />} />
                    <Route path="quiz" element={<Quiz />} />
                    <Route path="congratulations" element={<Congratulations />} />
                    <Route path="try-again" element={<TryAgain />} />
                    <Route path="dsa_admin_login" element={<Login />} />
                    <Route path="dsa_admin" element={<Dashboard />} />
                    <Route path="dsa_admin/users" element={<UserList />} />
                    <Route path="dsa_admin/questions" element={<QuestionEditor />} />
                    <Route path="dsa_admin/cupons" element={<CupomManager />} />
                    <Route path="user-profile/:userId" element={<UserProfile />} />
                    <Route path="inicio" element={<Inicio user={user} />} />
                    <Route path="nao-passou" element={<NaoPassou />} />
                    <Route path="termos-e-condicoes" element={<TermosECondicoes />} />
                    <Route path="politica-de-privacidade" element={<PoliticaDePrivacidade />} />
                    <Route path="politica-de-cookies" element={<PoliticaDeCookies />} />
                    <Route path="regulamento" element={<Regulamento />} />
                    <Route path="minha-conta" element={<MinhaConta />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="dsa_admin/exportar-participantes" element={<ExportXLSX />} />
                  </Routes>
                </QuizProvider>
              </AuthProvider>
            </>
          }/>

          {/* ROTAS CB2024 */}
          <Route path="/cb2024/*" element={
            <>
              <GoogleAnalytics trackingId="G-5R9L6LZXMJ" />
              <AuthProviderCB2024>
                <QuizProviderCB2024>
                  <Routes>
                    <Route path="/" element={<HomeCB2024 />} />
                    <Route path="register" element={<RegisterCB2024 />} />
                    <Route path="quiz" element={<QuizCB2024 />} />
                    <Route path="congratulations" element={<CongratulationsCB2024 />} />
                    <Route path="try-again" element={<TryAgainCB2024 />} />
                    <Route path="dsa_admin_login" element={<LoginCB2024 />} />
                    <Route path="dsa_admin" element={<DashboardCB2024 />} />
                    <Route path="dsa_admin/users" element={<UserListCB2024 />} />
                    <Route path="dsa_admin/questions" element={<QuestionEditorCB2024 />} />
                    <Route path="dsa_admin/cupons" element={<CupomManagerCB2024 />} />
                    <Route path="user-profile/:userId" element={<UserProfileCB2024 />} />
                    <Route path="inicio" element={<InicioCB2024 user={userCB2024} />} />
                    <Route path="nao-passou" element={<NaoPassouCB2024 />} />
                    <Route path="termos-e-condicoes" element={<TermosECondicoesCB2024 />} />
                    <Route path="politica-de-privacidade" element={<PoliticaDePrivacidadeCB2024 />} />
                    <Route path="politica-de-cookies" element={<PoliticaDeCookiesCB2024 />} />
                    <Route path="regulamento" element={<RegulamentoCB2024 />} />
                    <Route path="minha-conta" element={<MinhaContaCB2024 />} />
                    <Route path="dsa_admin/exportar-participantes" element={<ExportXLSXCB2024 />} />

                    <Route path="*" element={<NotFoundCB2024 />} />
                  </Routes>
                </QuizProviderCB2024>
              </AuthProviderCB2024>
            </>
          }/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;