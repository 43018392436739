import React, { useState, useEffect, useContext } from 'react';
import { getParticipantsReport } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import logoPanini from '../images/logo_panini.png';
import styles from './ExportXLSX.module.css';

const ExportXLSX = () => {
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedOption, setSelectedOption] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');


  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      navigate('/cob/dsa_admin_login');
    }
  }, [user, navigate]);

  const handleExport = async () => {
    try {
      const initialTimestamp = new Date(initialDate);
      const endTimestamp = new Date(endDate);
      await getParticipantsReport(initialTimestamp, endTimestamp, selectedOption);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Falha ao exportar.');
    }
  };

  return (
    <div className={styles.dashboard}>
      <header className={styles.header}>
        <img src={logoPanini} alt="Logo Panini" className={styles.logo} />
      </header>
      <main className={styles.content}>
        <button onClick={() => navigate('/cob/dsa_admin')} className={styles.voltar}>Voltar ao Painel</button>
        <h1>Relatório Participantes COB</h1>
        <div className={styles.report}>
          <label htmlFor="reportType">Selecionar:</label>
          <select
            id="reportType"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            className={styles.select}
          >
            <option value="1">Data de registro</option>
            <option value="2">Última vez jogado</option>
          </select>
          <label htmlFor="initialDate">De:</label>
          <input
            type="date"
            id="initialDate"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
            className={styles.input}
          />
          <label htmlFor="endDate">Até:</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={styles.input}
          />
          <button onClick={handleExport} className={styles.downloadButton}>Baixar Relatório</button>
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        </div>
      </main>
    </div>
  );
};

export default ExportXLSX;
