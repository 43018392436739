import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, doc, setDoc, getDocs, query, where, updateDoc, orderBy, limit } from "firebase/firestore";
import * as XLSX from 'xlsx';
import axios from "axios";
const firebaseConfig = {
    apiKey: "AIzaSyBbng94kjwYNthqMHLo9CSZW4bGLUF6_l8",
    authDomain: "digital-sport-academy-ea5a5.firebaseapp.com",
    projectId: "digital-sport-academy-ea5a5",
    storageBucket: "digital-sport-academy-ea5a5.appspot.com",
    messagingSenderId: "255923649319",
    appId: "1:255923649319:web:7cad665ef66f0abbec4702"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Cob Functions

export const addUser = async (data) => {
    console.log("Adicionando usuário", data);
    try {
        const docRef = await addDoc(collection(db, 'participantes'), {
            ...data,
            attempts: 0,
            passed: false,
            couponCode: null,
            fromCOB: true,
        });
        console.log(`Dados do usuário adicionados com sucesso para o ID: ${docRef.id}`);
    } catch (error) {
        console.error("Erro ao adicionar usuário ao Firestore:", error);
        if (error.code === 'not-found') {
            console.log("Criando coleção 'participantes'...");
            await setDoc(doc(db, 'participantes', 'dummy'), { dummy: 'data' });
            console.log("Coleção 'participantes' criada. Tentando adicionar usuário novamente...");
            await addUser(data);
        }
    }
};

export const getUserByEmail = async (email) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCOB", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => doc.data())[0];
    console.log(`Buscando usuário pelo e-mail: ${email}, resultado: ${user}`);
    if(!user){
        console.error(`Usuário com e-mail ${email} não encontrado no Firestore.`);
    }
    return user;
};

export const getAdminByEmail = async (email) => {
    const adminsRef = collection(db, 'admins');
    const q = query(adminsRef, where("email", "==", email));
    const snapshot = await getDocs(q);
    const admin = snapshot.docs.map(doc => doc.data())[0];
    console.log(`Buscando admin pelo e-mail: ${email}, resultado: ${admin}`);
    if(!admin){
        console.error(`Admin com e-mail ${email} não encontrado no Firestore.`);
        return null; // Retorne nulo se o admin não existir
    }
    return admin;
};

export const updateUser = async (email, updateFields) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCOB", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    if (user) {
        const userRef = doc(db, 'participantes', user.id);
        try {
            await updateDoc(userRef, updateFields);
        } catch (error) {
            console.error("Erro ao atualizar usuário no Firestore:", error);
        }
    } else {
        console.error(`Usuário com e-mail ${email} não encontrado no Firestore.`);
    }
};

export const updateParticipant = async (email, data) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCOB", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    if (user) {
        const userRef = doc(db, 'participantes', user.id);
        try {
            await updateDoc(userRef, data);
            console.log(`Dados do participante com o e-mail ${email} atualizados com sucesso.`);
        } catch (error) {
            console.error(`Erro ao atualizar os dados do participante no Firestore:`, error);
        }
    } else {
        console.error(`Participante com e-mail ${email} não encontrado no Firestore.`);
    }
};

export const addCoupon = async (data) => {
    console.log("Adicionando cupom: ", data);
    const couponsRef = collection(db, 'coupons');
    const docRef = await addDoc(couponsRef, data);
    console.log(`Cupom adicionado com sucesso para o ID: ${docRef.id}`);
    return docRef;
};

export const getCoupons = async () => {
    console.log("Buscando todos os cupons...");
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("from", "==", "cob"));
    const snapshot = await getDocs(q);
    const coupons = snapshot.docs.map(doc => doc.data());
    console.log("Cupons recuperados: ", coupons);
    return coupons;
};

export const searchCoupon = async (code) => {
    console.log(`Buscando cupom com o código: ${code}`);
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("code", "==", code), where("from", "==", "cob"));
    const snapshot = await getDocs(q);
    const coupon = snapshot.docs.map(doc => doc.data())[0];
    console.log(`Cupom encontrado: ${coupon}`);
    return coupon;
};

export const getUnusedCoupon = async () => {
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("used", "==", false), orderBy('createdAt'), limit(1), where("from", "==", "cob"));
    const snapshot = await getDocs(q);
    const coupon = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    return coupon;
};

export const updateCoupon = async (couponId, data) => {
    const couponRef = doc(db, 'coupons', couponId);
    try {
        await updateDoc(couponRef, data);
        console.log(`Cupom com ID ${couponId} atualizado com sucesso.`);
    } catch (error) {
        console.error(`Erro ao atualizar o cupom no Firestore:`, error);
    }
};

export const getParticipantsReport = async (fromDate, toDate, selectedOption) => { 
    const participantesRef = collection(db, 'participantes');
    try {
        let queryParameter = "firstLogin";
        let allDocs = [];

        if (selectedOption === '2') {
            queryParameter = "lastPlayedDateCB2024";
            const qur = query(participantesRef, where("firstLogin", ">=", fromDate), where("firstLogin", "<=", toDate), where("fromCOB", "==", true));
            const firstLoginSnapshot = await getDocs(qur);
            allDocs = firstLoginSnapshot.docs;
        }
        
        console.log('Participantes COB XLSX');
        const q = query(participantesRef, where(queryParameter, ">=", fromDate), where(queryParameter, "<=", toDate), where("fromCOB", "==", true));
        const snapshot = await getDocs(q);
        allDocs = allDocs.concat(snapshot.docs); // Add snapshot docs to allDocs
        
        const participantsData = await Promise.all(allDocs.map(async (doc) => {
            const data = doc.data();

            const formatDate = (timestamp) => {
                let date;
                if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
                    // Firestore Timestamp object
                    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
                    date = new Date(milliseconds);
                } else {
                    // ISO 8601 string
                    date = new Date(timestamp);
                }
            
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            };

            const email = data.email;
            const url = `https://accounts.us1.gigya.com/accounts.search?apiKey=4_zonqrz6ML5wom_WGpIvucA&userKey=ADMA8fIJ05E2&secret=QhdbAo8Ihw9fTEbPuNjWyik0XmoQekSU&query=SELECT * FROM accounts WHERE profile.email = '${email}'`;

            const response = await axios.get(url);
            let gigyaData = response.data.results[0];

            return {
                Email: data.email,
                FirstName: gigyaData.profile.firstName,
                LastName: gigyaData.profile.lastName,
                CPF: gigyaData.data ? (gigyaData.data.taxvat || '') : '',
                Sex: gigyaData.profile.gender ? gigyaData.profile.gender : '',
                Age: gigyaData.profile.age ? gigyaData.profile.age : '',
                Register: formatDate(data.firstLogin),
                LastLogin: formatDate(data.lastLogin),
                LastPlayed: data.lastPlayedDateCOB ? formatDate(data.lastPlayedDateCOB) : 'N/A',
                CouponCode: data.couponCodeFromCOB ? data.couponCodeFromCOB : data.couponCode,
                Attempts: data.attempts,
                CorrectAnswers: data.correctAnswers,
                Passed: data.passed ? 'Yes' : 'No',
                RegistrationWebsite: gigyaData.regSource
            };
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(participantsData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'COB Participants Report');

        const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        const buffer = new ArrayBuffer(workbookBinary.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < workbookBinary.length; i++) {
            view[i] = workbookBinary.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const formatDateXLSX = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        
        const formattedFromDate = formatDateXLSX(new Date(fromDate));
        const formattedToDate = formatDateXLSX(new Date(toDate));
        
        link.download = `COB_Participants_Report_${formattedFromDate}_${formattedToDate}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error(`Erro ao baixar XLSX:`, error);
    }
}

// CB2024 Functions

export const getParticipantsReportCB2024 = async (fromDate, toDate, selectedOption) => { 
    const participantesRef = collection(db, 'participantes');
    try {
        let queryParameter = "firstLogin";
        if (selectedOption === '2') {
            queryParameter = "lastPlayedDateCB2024";
        }
        console.log('Participantes CB2024 XLSX');
        const q = query(participantesRef, where(queryParameter, ">=", fromDate), where(queryParameter, "<=", toDate), where("fromCB2024", "==", true));
        const snapshot = await getDocs(q);
        const participantsData = await Promise.all(snapshot.docs.map(async (doc) => {
            const data = doc.data();

            const formatDate = (timestamp) => {
                let date;
                if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
                    // Firestore Timestamp object
                    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
                    date = new Date(milliseconds);
                } else {
                    // ISO 8601 string
                    date = new Date(timestamp);
                }
            
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            };
            const email = data.email;
            const url = `https://accounts.us1.gigya.com/accounts.search?apiKey=4_zonqrz6ML5wom_WGpIvucA&userKey=ADMA8fIJ05E2&secret=QhdbAo8Ihw9fTEbPuNjWyik0XmoQekSU&query=SELECT * FROM accounts WHERE profile.email = '${email}'`;

            const response = await axios.get(url);
            let gigyaData = response.data.results[0];
            
            return {
                Email: data.email,
                FirstName: gigyaData.profile.firstName,
                LastName: gigyaData.profile.lastName,
                CPF: gigyaData.data ? (gigyaData.data.taxvat || '') : '',
                Sex: gigyaData.profile.gender ? gigyaData.profile.gender : '',
                Age: gigyaData.profile.age ? gigyaData.profile.age : '',
                Register: formatDate(data.firstLogin),
                LastLogin: formatDate(data.lastLogin),
                LastPlayed: data.lastPlayedDateCB2024 ? formatDate(data.lastPlayedDateCB2024) : 'N/A',
                CouponCode: data.couponCodeFromCB2024,
                Attempts: data.attemptCB2024,
                CorrectAnswers: data.correctAnswersCB2024,
                Passed: data.passedCB2024 ? 'Yes' : 'No',
                RegistrationWebsite: gigyaData.regSource
            };
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(participantsData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'CB2024 Participants Report');

        const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        const buffer = new ArrayBuffer(workbookBinary.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < workbookBinary.length; i++) {
            view[i] = workbookBinary.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const formatDateXLSX = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        
        const formattedFromDate = formatDateXLSX(new Date(fromDate));
        const formattedToDate = formatDateXLSX(new Date(toDate));
        
        link.download = `CB2024_Participants_Report_${formattedFromDate}_${formattedToDate}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error(`Erro ao baixar XLSX:`, error);
    }
}

export const addUserCB2024 = async (data) => {
    console.log("Adicionando usuário CB2024", data);
    try {
        const docRef = await addDoc(collection(db, 'participantes'), {
            ...data,
            attempts: 0,
            passedCB2024: false,
            couponCodeFromCB2024: null,
            fromCB2024: true,
        });
        console.log(`Dados do usuário adicionados com sucesso para o ID: ${docRef.id}`);
    } catch (error) {
        console.error("Erro ao adicionar usuário CB2024 ao Firestore:", error);
        if (error.code === 'not-found') {
            console.log("Criando coleção 'participantes'...");
            await setDoc(doc(db, 'participantes', 'dummy'), { dummy: 'data' });
            console.log("Coleção 'participantes' criada. Tentando adicionar usuário novamente...");
            await addUser(data);
        }
    }
};

export const getUserByEmailCB2024 = async (email) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCB2024", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => doc.data())[0];
    console.log(`Buscando usuário pelo e-mail: ${email}, resultado: ${user}`);
    if(!user){
        console.error(`Usuário com e-mail ${email} não encontrado no Firestore.`);
    }
    return user;
};

export const updateUserCB2024 = async (email, updateFields) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCB2024", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    if (user) {
        const userRef = doc(db, 'participantes', user.id);
        try {
            await updateDoc(userRef, updateFields);
        } catch (error) {
            console.error("Erro ao atualizar usuário no Firestore:", error);
        }
    } else {
        console.error(`Usuário com e-mail ${email} não encontrado no Firestore.`);
    }
};

export const updateParticipantCB2024 = async (email, data) => {
    const usersRef = collection(db, 'participantes');
    const q = query(usersRef, where("email", "==", email), where("fromCB2024", "==", true));
    const snapshot = await getDocs(q);
    const user = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    if (user) {
        const userRef = doc(db, 'participantes', user.id);
        try {
            await updateDoc(userRef, data);
            console.log(`Dados do participante com o e-mail ${email} atualizados com sucesso.`);
        } catch (error) {
            console.error(`Erro ao atualizar os dados do participante no Firestore:`, error);
        }
    } else {
        console.error(`Participante com e-mail ${email} não encontrado no Firestore.`);
    }
};

export const addCouponCB2024 = async (data) => {
    console.log("Adicionando cupom CB2024: ", data);
    const couponsRef = collection(db, 'coupons');
    const docRef = await addDoc(couponsRef, data);
    console.log(`Cupom adicionado com sucesso para o ID: ${docRef.id}`);
    return docRef;
};

export const getCouponsCB2024 = async () => {
    console.log("Buscando todos os cupons...");
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("from", "==", "cb2024"));
    const snapshot = await getDocs(q);
    const coupons = snapshot.docs.map(doc => doc.data());
    console.log("Cupons CB2024 recuperados: ", coupons);
    return coupons;
};

export const searchCouponCB2024 = async (code) => {
    console.log(`Buscando cupom com o código: ${code}`);
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("code", "==", code), where("from", "==", "cb2024"));
    const snapshot = await getDocs(q);
    const coupon = snapshot.docs.map(doc => doc.data())[0];
    console.log(`Cupom CB 2024 encontrado: ${coupon}`);
    return coupon;
};

export const getUnusedCouponCB2024 = async () => {
    const couponsRef = collection(db, 'coupons');
    const q = query(couponsRef, where("used", "==", false), orderBy('createdAt'), limit(1), where("from", "==", "cb2024"));
    const snapshot = await getDocs(q);
    const coupon = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))[0];
    return coupon;
};

export const updateCouponCB2024 = async (couponId, data) => {
    const couponRef = doc(db, 'coupons', couponId);
    try {
        await updateDoc(couponRef, data);
        console.log(`Cupom com ID ${couponId} atualizado com sucesso.`);
    } catch (error) {
        console.error(`Erro ao atualizar o cupom CB 2024 no Firestore:`, error);
    }
};

export { auth, db };