import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import RegisterButton from './RegisterButton';
import header414 from '../images/HEADER-LP_414.png';
import header820 from '../images/HEADER-LP_820.jpg';
import header1280 from '../images/HEADER-LP_1280.jpg';
import header1920 from '../images/HEADER-LP_1920.jpg';
import stadiumImage from '../images/IMG-STADIO.jpg';
import appMockupDesktop from '../images/APP_MOCKUP LP.png';
import appMockupMobile from '../images/APP_MOCKUP_mobile.png';
import num1Image from '../images/num_1.png';
import num2Image from '../images/num_2.png';
import num3Image from '../images/num_3.png';
import num4Image from '../images/num_4.png';
import comboLogo from '../images/COMBOLOGO.png';
import homePopup from '../images/homePopup.png';
import './Home.css';
import Header from './Header'; // Importe o componente de cabeçalho 


Modal.setAppElement('#root');

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ageModalIsOpen, setAgeModalIsOpen] = useState(false);


  useEffect(() => {
    const didAcceptAgePrompt = window.localStorage.getItem('didAcceptAgePrompt');
    if (didAcceptAgePrompt === null) {
      setAgeModalIsOpen(true);
    }
    
    const didAcceptCookies = window.localStorage.getItem('didAcceptCookies');
    if (didAcceptCookies === null) {
      setAgeModalIsOpen(false);
      setModalIsOpen(true);
    }
  }, []);

  const closeAgeModal = () => {
    window.localStorage.setItem('didAcceptAgePrompt', 'true');
    setAgeModalIsOpen(false);
  };

  const closeModal = () => {
    window.localStorage.setItem('didAcceptCookies', 'true');
    setModalIsOpen(false);
    setAgeModalIsOpen(true);
  };

  const getHeaderImage = () => {
    const width = window.innerWidth;
    if (width <= 414) {
      return header414;
    } else if (width <= 820) {
      return header820;
    } else if (width <= 1280) {
      return header1280;
    } else {
      return header1920;
    }
  };

  const isMobileDevice = () => {
    const width = window.innerWidth;
    return width <= 820;
  };

  const columnClass = isMobileDevice() ? 'column column-mobile' : 'column';

  return (
    <div>
      {/* Age Modal */}
      <Modal 
      isOpen={ageModalIsOpen}
      onRequestClose={closeAgeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { 
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: { color: 'lightsteelblue' }
      }}
      className="age-prompt-modal"
    >
      <div className="modal-content-container">
        <img src={homePopup}  className='homePopup'/>
        <button onClick={closeAgeModal} className="acceptButton">Prosseguir</button>
      </div>
    </Modal>

      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}  // Adicione esta linha
        style={{
          overlay: { 
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: { color: 'lightsteelblue' }
        }}
        className="cookie-policy-modal"
      >
        <div className="modal-content">
          <p>
            Usamos cookies para melhorar nossos serviços, fazer ofertas pessoais e aprimorar sua experiência. 
            Se você não aceitar os cookies opcionais abaixo, sua experiência pode ser afetada. 
            Se quiser saber mais, por favor, leia a 
            <a className="cookie-link" href="/cob/politica-de-cookies"> Política de Cookies</a>
          </p>
          <button onClick={closeModal} className="acceptButton">Aceitar Cookies</button>
        </div>
      </Modal>

      <Header /> {/* Use o componente de cabeçalho */}
      <img src={getHeaderImage()} alt="Header" />
      <h1 className="main-title">
        BEM-VINDO À <br />
        <span className="red-text">DIGITAL SPORT ACADEMY</span> <br />
        POWERED BY PANINI!
      </h1>
      <p className="paragraph centered-text">
        A Digital Sport Academy é o projeto com o qual a Panini, com alguns importantes parceiros, quer apoiar jovens
        brasileiros que sonham em trabalhar no mundo dos esportes, oferecendo um programa de treinamento de qualidade focado em tópicos da indústria esportiva e oportunidades profissionais concretas.
      </p>
      <div className={`row ${isMobileDevice() ? 'row-mobile mobile-collab' : ''}`}>
        <div className={`${columnClass} d-flex align-items-center justify-content-center`}>
          <img src={stadiumImage} alt="Stadium" className="stadium-image" />
        </div>
        <div className={columnClass}>
          <div className="column-text">
            <h2 className="title collaboration-title">COLABORAÇÃO</h2>
            <p className="right-column-text">
              Graças à colaboração com a <a href="https://playyourfuture.org/pt-pt/">Play Your Future</a>, a Panini oferece
              aos jovens um plano de treinamento criado e organizado por um
              Comitê Acadêmico Internacional, em português de Portugal, que aborda os principais temas da indústria do esporte.
              <br/>
              <br/>
              Usando o AKTO, um aplicativo de educação e entretenimento, os jovens podem estudar,
              se envolver, subir no ranking para mostrar seu valor e
              ganhar prêmios, experiência e oportunidades profissionais.
              <br/>
              <br/>
              São:
            </p>
            <ul className="custom-list">
              <li>Mais de 400 videoaulas;</li>
              <li>12 disciplinas;</li>
              <li>3 níveis de dificuldade;</li>
              <li>36 testes e quizzes;</li>
              <li>Mais de 1.200 perguntas; e</li>
              <li>49 arquivos para download.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row background-gray">
        <div className="column">
          <img src={isMobileDevice() ? appMockupMobile : appMockupDesktop} alt="App Mockup" className="app-mockup" />
        </div>
      </div>
      <h2 className="section-title">AS REGRAS DO JOGO:</h2>
      <div className="row content-row">
        <div className="column">
          <div className="content-box">
            <div className={`content-box-inner ${isMobileDevice() ? 'content-box-inner-mobile' : ''}`}>
              <img src={num1Image} alt="Number 1" className="number-img" />
              <p>Faça o teste de admissão para avaliar seu conhecimento sobre a cultura esportiva geral.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="content-box">
            <div className={`content-box-inner ${isMobileDevice() ? 'content-box-inner-mobile' : ''}`}>
              <img src={num2Image} alt="Number 2" className="number-img" />
              <p>Responda corretamente 12 perguntas de 15 para receber o código que lhe permitirá acessar o conteúdo de treinamento dedicado ao Campus "Sport Digital Academy powered by Panini". Você pode repetir o teste no máximo 3 vezes.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="column">
          <div className="content-box">
            <div className={`content-box-inner ${isMobileDevice() ? 'content-box-inner-mobile' : ''}`}>
              <img src={num3Image} alt="Number 3" className="number-img" />
              <p>Depois de fazer o download do aplicativo AKTO e inserir seu código pessoal do Campus, inicie o treinamento no setor esportivo.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="content-box">
            <div className={`content-box-inner ${isMobileDevice() ? 'content-box-inner-mobile' : ''}`}>
              <img src={num4Image} alt="Number 4" className="number-img" />
              <p>Os primeiros que acertarem o quiz, ganharão o código de curso grátis. <a href='/cob/regulamento'>Consulte o regulamento</a></p>
            </div>
          </div>
        </div>
      </div>
      <RegisterButton />
      <div className="footer text-center">
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <footer className="footer">
          <div className="footer-links">
            <a className="footer-link" href="/cob/termos-e-condicoes">Termos e Condições</a>
            <span className="footer-link-separator">|</span>
            <a className="footer-link" href="/cob/politica-de-privacidade">Política de Privacidade</a>
          </div>
        </footer>

        <p className="footer-text">Promoção autorizada pelo Ministério da Fazenda/SPA nº 03.034845/2024</p>
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  );
};

export default Home;






