import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, doc, deleteDoc } from 'firebase/firestore';
import logoPanini from '../images/logo_panini.png';
import styles from './QuestionEditor.module.css';
import { AuthContextCB2024 } from './AuthContextCB2024';

const QuestionEditorCB2024 = () => {
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState(["", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [search, setSearch] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { userCB2024 } = useContext(AuthContextCB2024);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userCB2024) {
      navigate('/cb2024/dsa_admin_login');
    }
  }, [userCB2024, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleAnswerChange = (index, e) => {
    const newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
  };

  const handleCorrectAnswerChange = (e) => {
    setCorrectAnswer(e.target.value);
  };

  const fetchQuestions = async () => {
    const questionsCollection = query(collection(db, "questions"));
    const questionSnapshot = await getDocs(questionsCollection);
    const questionList = questionSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setQuestions(questionList);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const addQuestionToFirestore = async (e) => {
    e.preventDefault();

    const questionData = {
      text: question,
      answers: answers,
      correctAnswer: correctAnswer,
    };

    try {
      const docRef = await addDoc(collection(db, "questions"), questionData);
      console.log("Document written with ID: ", docRef.id);
      setQuestion("");
      setAnswers(["", "", ""]);
      setCorrectAnswer("");
      setSuccessMessage("Pergunta adicionada com sucesso!");
      setTimeout(() => setSuccessMessage(""), 3000);
      fetchQuestions();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDeleteQuestion = async (id) => {
    if (window.confirm("Tem certeza que deseja deletar esta pergunta?")) {
      const questionDoc = doc(db, 'questions', id);
      await deleteDoc(questionDoc);
      fetchQuestions();
    }
  };

  const filteredQuestions = questions.filter(q =>
    q.text.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <img src={logoPanini} alt="Logo Panini" className={styles.logo} />
      </div>
      <div className={styles['dashboard-content']}>
        <div className={styles['content-box']}>
          <form onSubmit={addQuestionToFirestore}>
            <div className={styles['form-row']}>
              <label>
                Pergunta:
                <input type="text" value={question} onChange={handleQuestionChange} />
              </label>
            </div>
            <div className={styles['form-row']}>
              {answers.map((answer, index) => (
                <label key={index}>
                  Resposta {index + 1}:
                  <input type="text" value={answer} onChange={(e) => handleAnswerChange(index, e)} />
                </label>
              ))}
            </div>
            <div className={styles['form-row']}>
              <label>
                Resposta correta (número da resposta):
                <input type="number" min="1" max="3" value={correctAnswer} onChange={handleCorrectAnswerChange} />
              </label>
            </div>
            <div className={styles['form-row']}>
              <button type="submit">Adicionar pergunta</button>
            </div>
            {successMessage && <p>{successMessage}</p>}
          </form>
          <button onClick={handleBack} className={styles.voltar}>Voltar</button>
        </div>
        <div className={styles.searchBox}>
          <input type="text" value={search} onChange={handleSearchChange} placeholder="Buscar perguntas..." />
          {search && filteredQuestions.map((question, index) => (
            <div key={index} className={styles["question-text"]}>
              <h2>{question.text}</h2>
              {question.answers.map((answer, answerIndex) => (
                <p key={answerIndex} className={styles.answer}>
                  Resposta {answerIndex + 1}: {answer}
                </p>
              ))}
              <p>Resposta correta: {question.correctAnswer}</p>
              <button onClick={() => handleDeleteQuestion(question.id)} className={styles.deleteButton}>Deletar</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionEditorCB2024;

