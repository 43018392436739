import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NaoPassou.module.css';
import logo from '../images/logo_panini.png';
import comboLogo from '../images/COMBOLOGO.png';
import congratulationsImg from '../images/errou.png';
import Header from './Header'; // Importe o componente de cabeçalho 


const NaoPassou = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate("/");
  }

  return (
  <>
    <Header /> {/* Use o componente de cabeçalho */}

    <div className={styles.container}>
     <img src={congratulationsImg} alt="Congratulations" className={styles.congratulationsImg} />
      <h2 className={styles.subtitle}>Que Pena.</h2>
      <p className={styles.text}>Infelizmente, você não passou no teste e não pode acessar a "Digital Sport Academy" powered by Panini.</p>
      <p className={styles['text-bold']}>Continue a cultivar sua paixão pelo esporte e foque no seu objetivo.</p>
      <p className={styles['text-thankyou']}>Agradecemos a sua participação, Equipe Panini</p>
      
      <button className={styles.button} onClick={handleStart}>Sair</button>
      <div className={styles.footer}>
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  </>
);

};

export default NaoPassou;
