import React from 'react';
import comboLogo from '../images/COMBOLOGO.png';

import './Regulamento.css';
import Header from './Header'; // Importe o componente de cabeçalho 


const Regulamento = () => {
    return ( <div>
      <Header /> {/* Use o componente de cabeçalho */}
      <h1 className="main-title">
        REGULAMENTO <br />
        <span className="red-text">DIGITAL SPORT ACADEMY - PANINI</span>
      </h1>
      <br />
      <p className="paragraph justified-text">
        <h3>1.	EMPRESA PROMOTORA:</h3><br />
        1.1.	Razão Social: Panini Brasil Ltda<br />
        1.2.	Endereço: Alameda Caiapós, 425 – Tamboré – Barueri/SP – 06460-110<br />
        1.3.	CNPJ nº. 58.732.058/0001-00
      </p>
      <p className="paragraph justified-text">
        <h3>2.	CRITÉRIO DE PARTICIPAÇÃO:</h3><br />
        2.1. O presente concurso <strong>“Digital Sport Academy”</strong> será dividido em duas etapas e é aberto para todas as pessoas físicas com idade igual ou superior a 16 (dezesseis) anos, residentes e domiciliadas no território nacional, no período de 28 de junho de 2024 a 31 de dezembro de 2024 ou enquanto durar o estoque de prêmios do concurso, de acordo com as condições previstas neste Regulamento.<br /><br />

        <p className='justified-text-ident'>2.1.1. Na primeira etapa do concurso serão distribuídos 200 (duzentos) Cursos Online Digital Sport Academy, (denominado “prêmio”, nos termos deste regulamento) através de Quis com perguntas e Respostas, caso sejam distribuídos antes do término, a primeira etapa do concurso será considerada encerrada, no entanto o Quiz ficará disponível apenas para recreação e teste de conhecimentos.</p><br />

        <p className='justified-text-ident'>2.1.2. O curso Digital Sport Academy é o projeto com o qual a Panini oferece aos jovens um plano de treinamento criado e organizado por um Comitê Acadêmico Internacional, em português de Portugal, que aborda os principais temas da indústria do esporte, para apoiar jovens que sonham em trabalhar no mundo dos esportes, oferecendo um programa de treinamento de qualidade focado em tópicos da indústria esportiva e oportunidades profissionais concretas.</p><br />

        <p className='justified-text-ident'>2.1.3. Na segunda etapa do concurso serão distribuídos 03 (três) prêmios (WorkShop com a liderança da Panini, vale-cultura e ingressos para jogo do Brasileirão) através da conclusão do curso Digital Sport Academy, caso os prêmios sejam distribuídos antes do término, a segunda etapa do concurso será considerada encerrada, no entanto o curso ficará disponível para que os demais participantes possam concluir e obter conhecimentos sobre os temas de esportes.</p><br />

        2.2. Os interessados em participar do concurso <strong>“Digital Sport Academy”</strong> deverão acessar o site <strong>https://digitalsportacademy.com.br/</strong> no período entre o dia 28 de junho de 2024 até o dia 31 de dezembro de 2024 ou enquanto durar o estoque de prêmios do concurso, se cadastrar no concurso com seus dados pessoais (Nome Completo, CPF, E-mail, Data de nascimento, Gênero) e senha de acesso, sendo obrigatório realizar o opt-in nos Termo de Condições do concurso e Política de Privacidade, sem seguida poderão participar da primeira etapa do concurso e responder as perguntas do Quiz.<br /><br />

        <p className='justified-text-ident'>2.2.1. É obrigatório que o participante deste concurso cadastre seus dados pessoais válidos e atualizados, uma vez que esses serão utilizados para identificação e localização de cada ganhador deste concurso e consequente entrega do prêmio. Dessa forma, a Promotora do concurso não será responsável de ficar impossibilitada de entregar o prêmio, em razão do fornecimento de dados incompletos e incorretos.</p><br />

        <p className='justified-text-ident'>2.2.2. Os participantes assumem total responsabilidade pelos dados cadastrados e enviados no concurso, para comunicação e entrega do prêmio, não podendo imputar a quem quer que seja a responsabilidade pelo não recebimento decorrente de incorreção nos dados informados, ou ausência de local para recebimentos do prêmio.</p><br />

        2.3. Após a conclusão do cadastro os participantes deverão responder um Quiz contendo 15 perguntas aleatórias de múltiplas escolhas sobre os esportes olímpicos, sendo que ao ser um dos 200 (duzentos) primeiros a acertar pelo menos 12 respostas corretas, o participante será contemplado com o curso o Digital Sport Academy.<br /><br />

        2.4. A participação no presente concurso será limitada a 03 (três) Quis (chances), desde que atenda as condições previstas neste regulamento, sendo que cada participante poderá ser contemplado apenas uma única vez no concurso. <br /><br />

        2.5. Na segunda etapa do concurso os 03 (três) primeiros participantes que concluírem o curso Digital Sport Academy powered by Panini, serão contemplados com dos prêmios do concurso, de acordo com a ordem de conclusão.<br /><br />

        2.6. Os participantes também não poderão utilizar-se de meios escusos para participar desta promoção e/ou de mecanismos que criem condições de participações irregulares, desleais ou que atentem contra os objetivos e condições de participação previstas neste regulamento. As situações descritas, quando identificadas, serão consideradas como infração aos termos do presente regulamento, ensejando o impedimento da participação e/ou o imediato cancelamento da inscrição do participante, sem prejuízo, ainda, das medidas cabíveis e/ou ação de regresso a ser promovida pela promotora em face do infrator.<br /><br />

        2.7. Considerando que o cadastro na promoção será informatizado, no caso de uma eventual falha do sistema será solicitada a imediata reparação, no entanto, será necessário que o interessado em participar do concurso aguarde até que o retorno do referido sistema, mesmo que seja necessário acessar o sistema em outra data ou horário, durante o período de participação do concurso, consequentemente, sem caracterizar qualquer tipo de ônus para a empresa, em qualquer um dos casos, uma vez que a participação na promoção é exclusiva pelo sistema do concurso.<br /><br />

        2.8. A empresa Promotora poderá ainda, por motivos de força maior, suspender por curto período a troca promocional, mediante a autorização da SPA/MF, sendo certo que, nessa hipótese, a empresa Promotora tomará as medidas necessárias para solucionar o problema e retomar o sistema informatizado, o mais rápido possível, permanecendo inalteradas as regras e condições de validade de participação desta promoção.
      </p>
      <p className="paragraph justified-text">
        <h3>3.	APURAÇÃO E DESCRIÇÃO DE PRÊMIOS:</h3><br />
        3.1.	Serão distribuídos 200 (duzentos) prêmios na primeira etapa do concurso “Digital Sport Academy” no período de 28 de junho de 2024 a 31 de dezembro de 2024 ou enquanto durar os estoques, o que ocorrer primeiro.
        <br /><br />
          <div>
            <table>
              <tr>
                <th>Qtd/Ordem</th>
                <th>Descrição do prêmio</th>
                <th>Valor unitário</th>
              </tr>
              <tr>
                <td>1º ao 200º</td>
                <td>01 (um) Curso online “Sport Digital Academy powered by Panini”, código válido até 31/12/2024</td>
                <td>R$ 100,00</td>
              </tr>
            </table>
          </div>
        <br />
        3.2.	Serão distribuídos 03 (três) prêmios na segunda etapa do concurso “Digital Sport Academy” no período de 28 de junho de 2024 a 31 de dezembro de 2024 ou enquanto durar os estoques, o que ocorrer primeiro.
        <br /><br />
          <div>
            <table>
              <tr>
                <th>Qtd/Ordem</th>
                <th>Descrição do prêmio</th>
                <th>Valor unitário</th>
              </tr>
              <tr>
                <td>1º</td>
                <td>01 (um) Workshop virtual com a liderança da Panini</td>
                <td>R$ 500,00</td>
              </tr>
              <tr>
                <td>2º</td>
                <td>01 (um) Cartão vale-cultura no valor de R$ 400,00, sem função de saque.</td>
                <td>R$ 400,00</td>
              </tr>
            <tr>
              <td>3º</td>
              <td>02 (dois) Ingressos para o jogo do Brasileirão de 2024</td>
              <td>R$ 300,00</td>
            </tr>
            </table>
          </div>
      </p>	
      <p className="paragraph justified-text">
        <h3>4.	PREMIAÇÃO TOTAL:</h3><br />
            <div>
              <table>
              <tr>
              <th>Quantidade Total de Prêmios</th>
              <th>Valor Total do concurso</th>
              </tr>
              <tr>
              <td>203</td>
              <td>R$ 21.200,00</td>
              </tr>
              </table>
            </div>
      </p>	
      <p className="paragraph justified-text">
      <h3>5.	FORMA DE APURAÇÃO:</h3><br />	
          5.1.	A definição dos contemplados da primeira etapa será com base na ordem de contemplação, sendo que serão contemplados os 200 (duzentos) primeiros participantes que acertarem pelo menos 12 respostas corretas do Quis, de acordo com a data, hora, minuto e segundo.<br /><br />

          5.2.	A definição dos contemplados da segunda etapa será com base na ordem de contemplação, sendo que serão contemplados os 03 (três) primeiros participantes que concluírem o curso Digital Sport Academy powered by Panini, serão contemplados com dos prêmios do concurso, de acordo com a ordem de conclusão com base na data, hora, minuto e segundo.<br /><br />

          5.3.	A apuração será realizada por membros da empresa promotora que possuem reconhecida capacidade e saber notório relacionado às regras do presente concurso, cuja decisão desses membros será considerada soberana e irrecorrível.
      </p>
      <p className="paragraph justified-text">
      <h3>6.	CRITÉRIOS DE DESCLASSIFICAÇÃO:</h3><br />	
          6.1.	Não terão validade inscrições que não preencherem as condições básicas previstas neste regulamento e que impossibilitarem a verificação de sua autenticidade valendo apenas os cupons de participação originais impressos pela Promotora.<br /><br />

          6.2.	Presume, ainda, a promotora que as participações devam ser originadas de endereços eletrônicos (IP) diferentes para participantes diferentes, e a aglutinação de participações oriundas de endereços eletrônico (IP) iguais presume a formação de alguma associação para participação, que eventualmente, possa ser vedada pelo regulamento do concurso e que, portanto, será investigada com mais rigor, para definir sua adesão a estas regras, sob pena de desclassificação.<br /><br />

          6.3.	Os participantes poderão ser excluídos automaticamente do concurso em caso de comprovação de fraude, de não preenchimento dos requisitos previamente determinados e/ou em decorrência da prestação de informações incorretas ou equivocadas, de acordo com as regras do regulamento do concurso.<br /><br />

          6.4.	Uma vez identificada à prática de ato tipificado como ilícito penal, o contemplado responderá pelo crime de falsificação de documentos ou uso de documento falso, sem prejuízo de responder civilmente por seus atos.
      </p>
      <p className="paragraph justified-text">
      <h3>7.	FORMA DE DIVULGAÇÃO DO RESULTADO:</h3><br /> 
          7.1.	O nome dos contemplados serão disponibilizados para consulta no hotsite <strong>https://digitalsportacademy.com.br/ </strong> no prazo de até 10 (dez) dias, a contar da data da respectiva apuração.<br /><br />

          7.2.	O contemplado autoriza o uso de sua imagem, voz, desempenho e nome, em filmes publicitários e institucionais veiculados em mídia eletrônica, fotos, cartazes, anúncios em jornais e revistas e em qualquer outra forma de mídia impressa, para divulgação do concurso no território nacional e exterior pelo período de até 01 (um) ano após a data da divulgação da contemplação.
      </p>
      <p className="paragraph justified-text">
      <h3>8.	ENTREGA DOS PRÊMIOS:</h3><br />
          8.1.	O código alfanumérico será enviado ao contemplado por e-mail no prazo de até 30 (trinta) dias da contemplação, de acordo com o Artigo 5º do Decreto 70.951/72.<br /><br />

          <p className='justified-text-ident'>8.1.1.	De posse do código alfanumérico o participante deverá baixar o aplicativo Okto, disponível para baixar na loja de aplicativos acessíveis em seus smartphones com sistema Android ou sistema IOS, durante o período de 00h00 do dia 28 de junho de 2022 às 23h59 (horário de Brasília) do dia 31 de dezembro de 2024, para criar ou acessar seu cadastro, e inserir o alfanumérico, para ter acesso ao curso  Digital Sport Academy. </p><br />
          
          <p className='justified-text-ident'>8.1.2.	O código alfanumérico poderá ser utilizado apenas uma única vez até 31/12/2024 e durante toda a promoção, sendo que após a utilização/resgate do código, o mesmo será bloqueado pelo sistema e não poderão ser reutilizados na promoção.</p><br />

          8.2.	O respectivo prêmio será entregue livre e desembaraçados de qualquer ônus para os contemplados.<br /><br />

          8.3.	Nos termos da legislação em vigor, não será permitida a conversão do prêmio em dinheiro ou, ainda, a sua substituição por outro, bem como a transferência do prêmio à terceiro, que não o contemplado, em data anterior à sua efetiva entrega e integralização ao patrimônio deste. <br /><br />	

          8.4.	Será resguardado o direito do contemplado de reclamar seu prêmio no prazo de até 180 (cento e oitenta dias), a contar a partir da data da respectiva apuração. Caso o prêmio ganho não seja reclamado no prazo caducará o direito do respectivo titular e o valor correspondente será recolhido pelas Empresas Promotoras, ao Tesouro Nacional como Renda da União, no prazo subsequente de 45 (dez) dias conforme art. 6º do decreto 70.951/72.
      </p>
      <p className="paragraph justified-text">
      <h3>9.	DISPOSIÇÕES GERAIS:</h3><br />
          9.1.	O regulamento do concurso está disponível no site https://digitalsportacademy.com.br/ <br /><br />

          9.2.	A participação na promoção implica na aceitação total e irrestrita de todos os termos deste regulamento. O participante declara que leu e concorda com o presente regulamento, comprometendo-se a obedecê-lo integralmente, previamente à sua participação na promoção. <br /><br />

          9.3.	Ao se cadastrar nesta promoção, o participante aceita todos os termos do presente Regulamento e autoriza o uso dos seus dados pessoais nos termos do Regulamento. <br /><br />

          9.4.	Os participantes também autorizam o uso de seus nomes, endereços físicos, eletrônicos telefones, RGs, CPFs, informados no ato do preenchimento do cupom para participação do concurso, com propósito de formação de cadastro da empresa promotora e aderentes, dados esses que não serão comercializados ou cedidos a terceiros, ainda que a título gratuito, conforme as regras definidas no Código de Defesa do Consumidor.<br /><br />

          9.5.	A Promotora, neste momento, assume o compromisso de proteger os dados pessoais cadastrados, mantendo absoluta confidencialidade sobre tais informações, garantindo que, excetuados os casos previstos em lei e ao fiel cumprimento da execução desta promoção, não serão compartilhados ou cedidos com terceiros a qualquer título. <br /><br />

          9.6.	Assim, os dados serão compartilhados apenas com as empresas contratadas pela Promotora, tais como: empresas responsáveis pelo sistema do banco de dados e por gerar os cupons, pela contabilidade, pela auditoria, pela autorização e prestação de contas do concurso junto à SPA/MF, pela assessoria jurídica, pela entrega dos prêmios, todas com a finalidade exclusiva de executar e operacionalizar a presente promoção. Os dados também serão compartilhados com a SPA/MF, órgão público responsável pela autorização, regulação e fiscalização das promoções comerciais, em atenção à legislação que rege o tema. <br /><br />

          9.7.	A Promotora exige que todas as empresas responsáveis pela execução e operacionalização desta promoção utilizem referidos dados pessoais em conformidade com este Regulamento e como a Lei Geral de Proteção de Dados (Lei nº 13.709/2018). <br /><br />

          9.8.	Internamente, os dados dos participantes serão acessados somente por colaboradores autorizados pela Promotora, respeitando os princípios inerentes ao tratamento de dados pessoais previstos na Lei Geral de Proteção de Dados, sempre com o objetivo de execução e operacionalização desta Promoção, além do compromisso de confidencialidade e preservação da privacidade, de acordo com este Regulamento. <br /><br />

          9.9.	Os dados pessoais coletados para esta promoção ficarão armazenados para fins operacionais e obedecerão a padrões rígidos de confidencialidade e segurança. Nenhum documento, informação e/ou dado pessoal será divulgado e/ou compartilhado em nenhuma hipótese, salvo os casos acima previstos e/ou mediante ordem judicial ou por determinação regulatória ou legal. <br /><br />

          9.10.	E em atenção às diretrizes legais aplicáveis, a Promotora possibilitará aos participantes que revoguem a autorização para uso de seus dados, para fins de execução desta promoção, concedida nos termos do regulamento, bastando que solicitem a empresa promotora através do endereço <a href="https://loja.panini.com.br/faleconosco">https://loja.panini.com.br/faleconosco</a> ou 08002371309<br /><br />

          9.11.	Na hipótese de a promoção ainda estar em curso, a revogação da autorização, pelos participantes, acarretará na sua imediata desclassificação e na cessação do envio de mensagens com os fins específicos descritos neste Regulamento. <br /><br />

          9.12.	Ao término do concurso, os dados pessoais de todos os participantes serão mantidos no banco de dados da Promotora pelo prazo de até 10 (dez) anos, ou até que haja o cancelamento, de forma expressa, das autorizações de manutenção dos dados previstas no Regulamento, considerando o fato que ocorrer primeiro, sem prejuízo do disposto no item abaixo. <br /><br />

          9.13.	A Promotora, para fins de cumprimento legal e/ou defesa em eventual processo administrativo e/ou judicial, manterá, obrigatoriamente, em sua base de dados, os dados pessoais: (i) dos participantes contemplados: pelo prazo de 5 (cinco) anos, contados do término do concurso; e (ii) dos demais participantes inclusive daqueles que cancelaram a autorização para participar desta promoção: até o recebimento, pela Promotora, do ofício de homologação da prestação de contas a ser realizada perante a SPA/MF, no prazo legal. Findos os prazos ora estipulados, os dados poderão ser deletados.<br /><br />

          9.14.	Os participantes também autorizam o uso de seus nomes, endereços físicos, eletrônicos telefones, RGs, CPFs, imagem, informados no ato do preenchimento do cadastro de participação do concurso, com propósito de formação de cadastro e mailing de comunicação da empresa promotora e aderentes, dados esses que não serão comercializados ou cedidos a terceiros, ainda que a título gratuito, conforme as regras definidas no Código de Defesa do Consumidor.<br /><br />

          9.15.	As dúvidas, omissões ou controvérsias oriundas da presente Promoção serão, preliminarmente, dirimidas por uma comissão composta por 03 (três) representantes das Empresas Promotoras. Na eventualidade de não se atingir um consenso após a atuação da comissão, a questão deverá, então, ser submetida à apreciação da SPA/MF. No silêncio injustificado das Empresas Promotoras, bem como em razão de decisão insatisfatória que esta vier a adotar quanto a eventuais solicitações de esclarecimentos que lhe forem apresentadas, poderão os consumidores participantes do concurso, apresentar suas reclamações fundamentadas ao Procon local e/ou aos órgãos públicos integrantes do Sistema Nacional de Defesa do Consumidor.<br /><br />

          9.16.	Os casos omissos e/ou eventuais controvérsias oriundas da participação na presente Promoção serão submetidas à comissão organizadora para avaliação, sendo que as decisões da comissão serão soberanas.<br /><br />

          9.17.	A Panini compromete-se a adquirir o prêmio e entregar a nota fiscal ou contrato de propriedade do prêmio em até 8 (oito) dias antes da data da respectiva apuração, conforme art. 34, inciso I da Portaria MF nº 41, de 2008.<br /><br />

          9.18.	Conforme o disposto no art. 70, inciso 1º, “b”, da Lei nº. 11.196, de 21/11/12, a empresa promotora recolherá 20% de IRF sobre o valor dos prêmios, até o 3º dia útil subsequente ao decêndio de ocorrência dos fatos geradores, através de DARF, recolhida na rede bancária, com o código 0916.<br /><br />

          9.19.	Ocorrerá prescrição do direito ao prêmio dentro de 180 (cento e oitenta) dias após a data do sorteio do concurso. Ocorrendo a prescrição ora mencionada não caberá ao vencedor qualquer tipo de reclamação e/ou reivindicação, a qualquer título que seja. O prêmio ganho e não reclamados reverterão como Renda da União, no prazo de 10 (dez) dias, de acordo com o Art. 6º do Decreto nº 70951/72.<br /><br />

          9.20.	Fica, desde já, eleito o foro da comarca do participante para solução de quaisquer questões referentes ao Regulamento da presente promoção. <br /><br />

          9.21.	Este concurso está de acordo com a legislação vigente (Lei n.º 5.768/71, regulamentada pelo Decreto n.º 70.951/72 e Portaria MF 41/08) e obteve o <strong>Certificado de Autorização SPA/MF nº. 03.034845/2024</strong> expedido pelo Ministério da Fazenda.  <br />
      </p><br />

      <div className="footer text-center">
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <footer className="footer">
          <div className="footer-links">
            <a className="footer-link" href="/termos-e-condicoes">Termos e Condições</a>
            <span className="footer-link-separator">|</span>
            <a className="footer-link" href="/politica-de-privacidade">Política de Privacidade</a>
          </div>
        </footer>

        <p className="footer-text">Promoção autorizada pelo Ministério da Fazenda/SPA nº 03.034845/2024</p>
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
</div>)
}

export default Regulamento