import React, { useState, useEffect } from 'react';
import { addUser, updateUser, getUserByEmail } from '../firebase'; 
import { useNavigate } from 'react-router-dom';
import comboLogo from '../images/COMBOLOGO.png';
import Header from './Header';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const Register = () => {
  let navigate = useNavigate();
  const [coupon, setCoupon] = useState(1);
  const [ageError, setAgeError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const couponsQuery = query(collection(db, "coupons"), where("used", "==", false), where("from", "==", "cob"));
          const couponSnapshot = await getDocs(couponsQuery);
  
          if (couponSnapshot.empty) {
            setCoupon(null)
          }
        } catch (error) {
          console.error("An error occurred while fetching the data:", error);
        }
      }
      fetchData();
  }, [])

  useEffect(() => {
    if (window.gigya) {
      window.gigya.accounts.showScreenSet({
        screenSet: 'Store-RegistrationLogin',
        startScreen: 'gigya-login-screen',
        containerID: 'gigya-container',
        lang: 'pt-BR',
      });

      window.gigya.accounts.addEventHandlers({
        onLogin: async (event) => {
          const email = event.profile?.email || "";
          const displayName = event.profile?.firstName || ""
          const lastName = event.profile?.lastName || ""
          const gender = event.profile?.gender || ""
          const age = event.profile?.age || 0;
          const CPF = event.data.taxvat
          const registrationWebsite = event.data.registration_website

          // Check if age is above 16
          if (age >= 16) {
            try {
              const existingUser = await getUserByEmail(email);
              if (existingUser) {
                await updateUser(email, { displayName: displayName, lastLogin: new Date(), fromCOB: true, age });

                if (existingUser.passed && existingUser.fromCOB === true) {
                  navigate('/cob/congratulations');
                } else if (existingUser.attempts < 3) {
                  navigate('/cob/inicio');
                } else if (existingUser.attempts === 3 && !existingUser.passed) {
                  navigate('/cob/nao-passou');
                }
              } else {
                const firstLogin = new Date();
                await addUser({ email, displayName: displayName, firstLogin, lastLogin: firstLogin, fromCOB: true, CPF: CPF, lastName, gender, registrationWebsite, age });
                navigate('/cob/inicio');
              }

              // Save user's email and age in localStorage
              window.localStorage.setItem('userAge', age);
              window.localStorage.setItem('userEmail', email);
            } catch (error) {
              console.error("Error handling user in Firestore:", error);
              navigate('/cob/inicio');
            }
          } else {
            setAgeError(true);
            window.localStorage.setItem('userEmail', email);
            window.localStorage.setItem('userAge', age);
            const gigyaContainer = document.getElementById('gigya-container');
            if (gigyaContainer) {
              while (gigyaContainer.firstChild) {
                gigyaContainer.removeChild(gigyaContainer.firstChild);
              }
            }
          }
        },
        onError: (error) => {
          console.log("Error occurred", error);
          navigate('/cob/inicio');
        }
      });
    }
  }, [navigate]);


  return (
    <div>
      <Header />

      {coupon ? (
        <div style={{ minHeight: '520px', marginTop: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {ageError ? (
              <h2 className="white-text" style={{ margin: '100px' }}>Desculpe, mas a idade para a realização do quiz é entre 16 e 25 anos.</h2>
          ) : (
            <div id="gigya-container"></div>
          )}
        </div>
      ) : (
        <div style={{ minHeight: '520px', marginTop: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h2 className="white-text" style={{ margin: '100px' }}>DESCULPE, ESSA CAMPANHA FOI ENCERRADA.</h2>
        </div>
      )}
     
      <div className="footer text-center">
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <footer className="footer">
          <div className="footer-links">
            <a className="footer-link" href="/cob/termos-e-condicoes">Termos e Condições</a>
            <span className="footer-link-separator">|</span>
            <a className="footer-link" href="/cob/politica-de-privacidade">Política de Privacidade</a>
          </div>
        </footer>
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  );
};

export default Register;
