import React, { createContext, useState } from 'react';
import { db } from '../firebase';

const QuizContext = createContext();

const QuizProvider = ({ children }) => {
  const [attempt, setAttempt] = useState(1);
  const [remainingAttempts, setRemainingAttempts] = useState(3);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [coupon, setCoupon] = useState(null);

  const getUnusedCoupon = async () => {
    const couponRef = db.collection('coupons');
    const snapshot = await couponRef
      .where('used', '==', false)
      .where('from', '==', 'cob')
      .limit(1)
      .get();
      
    if (snapshot.empty) {
      console.log('Não há cupons não utilizados disponíveis.');
      return;
    }

    let coupon = null;
    snapshot.forEach(doc => {
      coupon = { id: doc.id, ...doc.data() };
    });

    return coupon;
  }

  const updateCouponStatus = async (coupon) => {
    const couponRef = db.collection('coupons').doc(coupon.id);
    return couponRef.update({ used: true, from: 'cob' });
  }

  const handleQuizFinish = async (isPassed) => {
    setAttempt(attempt + 1);
    setRemainingAttempts(remainingAttempts - 1);
    if (isPassed) {
      const newCoupon = await getUnusedCoupon();
      if (newCoupon) {
        await updateCouponStatus(newCoupon);
        setCoupon(newCoupon);
      }
    }
  }

  const startNewQuiz = () => {
    setCorrectAnswers([]);
  }

  return (
    <QuizContext.Provider value={{ attempt, remainingAttempts, setAttempt, correctAnswers, setCorrectAnswers, handleQuizFinish, coupon, startNewQuiz }}>
      {children}
    </QuizContext.Provider>
  );
};

export { QuizContext, QuizProvider };



