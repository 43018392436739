import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; 

export const AuthContextCB2024 = createContext();

export const AuthProviderCB2024 = ({ children }) => {
  const [userCB2024, setuserCB2024] = useState(null);
  const adminEmail = "projetospanini@gmail.com"; // Insira o email do admin aqui

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (userCB2024) => {
      if (userCB2024) {
        setuserCB2024(userCB2024);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContextCB2024.Provider value={{ userCB2024, setuserCB2024, adminEmail }}>
      {children}
    </AuthContextCB2024.Provider>
  );
};
