import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where, doc, updateDoc, limit } from 'firebase/firestore';
import styles from './Congratulations.module.css';
import comboLogo from '../images/COMBOLOGO.png';
import androidLogo from '../images/android.png';
import appleLogo from '../images/apple.png';
import congratulationsImg from '../images/parabens.png';
import Header from './HeaderCB2024';

function CongratulationsCB2024() {
  const [coupon, setCoupon] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    const email = window.localStorage.getItem('userEmail');
    setUserEmail(email || "");
  }, []);

  useEffect(() => {
    console.log("useEffect triggered with email:", userEmail);

    const fetchData = async () => {
      try {
        console.log("Fetching user by email:", userEmail);
        const userQuery = query(collection(db, "participantes"), where("email", "==", userEmail), where("fromCB2024", "==", true));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          let userData = userSnapshot.docs[0].data();
          console.log("Fetched user data:", JSON.stringify(userData, null, 2));
          setCorrectCount(userData.correctAnswers);
          setDisplayName(userData.displayName);

          if (userData.couponCode) {
            setCoupon(userData.couponCode);
          } else {
            console.log("Fetching a coupon for the user...");
            const couponQuery = query(collection(db, "coupons"), where("used", "==", false), where("from", "==", "cb2024"), limit(1));
            const couponSnapshot = await getDocs(couponQuery);
            if (!couponSnapshot.empty) {
              let couponData = couponSnapshot.docs[0];
              let couponDocId = couponData.id;
              let couponCode = couponData.data().code;

              await updateDoc(doc(db, "coupons", couponDocId), {
                used: true,
              });

              await updateDoc(doc(db, "participantes", userSnapshot.docs[0].id), {
                couponCode: couponCode,
                couponCodeFromCB2024: couponCode,
              });

              await updateDoc(doc(db, "coupons", couponDocId), {
                usedBy: userEmail,
                usedByParticipant: userEmail,
              });

              setCoupon(couponCode);
            } else {
              console.log("No available coupons");
            }
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    }

    if (userEmail !== "") {
      fetchData();
    }
  }, [userEmail]);

  console.log("Rendering Congratulations with email:", userEmail);

   return (
    <>
      <Header /> {/* Use o componente de cabeçalho */}

      <div className={styles.container}>

        <img src={congratulationsImg} alt="Congratulations" className={styles.congratulationsImg} />
        <h2 className={styles.title}>Você passou no teste!</h2>
        <p className={styles.emailText}>{displayName},</p>
        <p>Parabéns por ter concluído o teste e obrigado participar!</p>
        <p>Você respondeu corretamente a {correctCount} perguntas de 15.</p>
        <p>Agora você tem acesso à "Digital Sport Academy powered by Panini"!</p>

        {coupon && <p className={`${styles.boldText} ${styles.marginText}`}>Use o código: {coupon}</p>}
        <p>para acessar o Campus e iniciar sua jornada de descoberta no setor esportivo.</p>
        <p>Faça o download do aplicativo AKTO nas lojas virtuais</p>

        <div className={styles.appLinks}>
          <a href="https://play.google.com/store/apps/details?id=com.playyourfuture.akto" target="_blank" rel="noopener noreferrer">
            <img src={androidLogo} alt="Android Logo" className={styles.appLogo} />
          </a>
          <a href="https://apps.apple.com/it/app/akto/id1469514061" target="_blank" rel="noopener noreferrer">
            <img src={appleLogo} alt="Apple Logo" className={styles.appLogo} />
          </a>
        </div>

        <p>Bons estudos e boa sorte!</p>
        <p> Equipe Panini </p>

        <div className={styles.footer}>
  <p className="footer-text">UM PROJETO DE</p>
  <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
  <p className="footer-text">© 2023 Digital Sport Academy</p>
</div>

      </div>
    </>
  );
}

export default CongratulationsCB2024;

