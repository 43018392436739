import React from 'react';
import { Link } from 'react-router-dom'; // Importando o componente Link
import styles from './NotFound.module.css'; // Importando os estilos
import logoPanini from '../images/logo_panini.png'; // Importe o logo da Panini (ajuste o caminho se necessário)

const NotFound = () => {
  return (
    <div>
      <div className={styles.header}>
        <Link to="/">
          <img src={logoPanini} alt="Logo Panini" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.space}></div>
      <h1 className={styles.h1}>404</h1>
      <p className={styles.message}>
        Desculpe, mas a página que você estava tentando visualizar não existe.
      </p>
    </div>
  );
};

export default NotFound;

