import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, updateUser, getUserByEmail, updateParticipant } from '../firebase';
import { collection, getDocs, query, setDoc, doc, getDoc } from 'firebase/firestore';
import { QuizContext } from './QuizContext';
import { AuthContext } from './AuthContext';
import styles from './Quiz.module.css';
import comboLogo from '../images/COMBOLOGO.png';
import Header from './Header'; // Importe o componente de cabeçalho

const Quiz = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem('timeLeft');
    return savedTime ? Number(savedTime) : 30;
  });
  const { attempt, setAttempt, handleQuizFinish } = useContext(QuizContext);
  const { user, loading } = useContext(AuthContext);
  const [showingAnswer, setShowingAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const email = window.localStorage.getItem('userEmail');
  const colors = ['#ed254e', '#ffc857', '#ff82a9', '#aad159', '#00a19a'];

  const currentQuestionIndexRef = useRef(0);

  const setCurrentQuestionIndex = (index) => {
    currentQuestionIndexRef.current = index;
    localStorage.setItem('currentQuestionIndex', index);
  };

  useEffect(() => {
    if (!email) {
      navigate('/cob/register');
    } else {
      getUserByEmail(email)
        .then((user) => {
          if (user) {
            user.attempts = user.attempts || 0;
            setAttempt(user.attempts);
            if (user.passed) {
              navigate('/cob/congratulations');
            } else if (user.attempts >= 3) {
              navigate('/cob/nao-passou');
            }
          } else {
            console.error(`User with email ${email} not found in Firestore.`);
          }
        })
        .catch((err) => {
          console.error(`Failed to fetch user data from Firestore for email ${email}`, err);
        });
    }
  }, [email, navigate, setAttempt]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const fetchQuestions = async () => {
    let selectedQuestions = JSON.parse(localStorage.getItem('selectedQuestions'));
    if (selectedQuestions === null) {
      const questionsCollection = query(collection(db, 'questions'));
      const questionSnapshot = await getDocs(questionsCollection);
      const questionList = questionSnapshot.docs.map((doc) => {
        let questionData = doc.data();
        questionData.correctAnswer = Number(questionData.correctAnswer) - 1;
        shuffleArray(colors);
        questionData.backgroundColors = [...colors];
        return questionData;
      });

      selectedQuestions = [];
      while (selectedQuestions.length < 15) {
        let question = questionList[Math.floor(Math.random() * questionList.length)];
        if (!selectedQuestions.includes(question)) {
          selectedQuestions.push(question);
        }
      }
      localStorage.setItem('selectedQuestions', JSON.stringify(selectedQuestions));
    }
    setQuestions(selectedQuestions);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          const newTimeLeft = prevTimeLeft - 1;
          localStorage.setItem('timeLeft', newTimeLeft); // Salve o tempo restante no localStorage
          return newTimeLeft;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);



useEffect(() => {
    fetchQuestions();
  }, []);

 

  useEffect(() => {
    if (timeLeft === 0) {
      setShowingAnswer(true);
      setCorrectAnswer(questions[currentQuestionIndexRef.current].correctAnswer);
    }
  }, [timeLeft, questions]);

  useEffect(() => {
    if (showingAnswer) {
      const timer = setTimeout(async () => {
        if (currentQuestionIndexRef.current < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndexRef.current + 1);
          setTimeLeft(30);
          if (email) {
            await setDoc(doc(db, 'quizState', email), {
              questions: JSON.stringify(questions),
              currentQuestionIndex: currentQuestionIndexRef.current,
              userAnswers: JSON.stringify(userAnswers),
            });
          }
        } else {
          handleQuizFinish();

          const correctAnswersCount = userAnswers.filter((answer) => answer).length;
          const passed = correctAnswersCount >= 12;
          const lastPlayedDateCOB = new Date().toISOString();

          if (email) {
            await updateUser(email, { attempts: attempt + 1, passed });
            const data = {
              attempt: attempt + 1,
              correctAnswers: correctAnswersCount,
              passed,
              fromCOB: true,
              lastPlayedDateCOB,
            };
            await updateParticipant(email, data);
          }

          localStorage.removeItem('selectedQuestions');
          if (email) {
            await setDoc(doc(db, 'quizState', email), {});
          }
          if (!passed) {
            if (attempt >= 2) {
              navigate('/cob/nao-passou');
            } else {
              setAttempt((prevAttempt) => prevAttempt + 1);
              setUserAnswers([]);
              setCurrentQuestionIndex(0);
              navigate('/cob/try-again', { state: { correctAnswers: userAnswers } });
            }
          } else {
            navigate('/cob/congratulations', {
              state: { correctAnswers: userAnswers, userEmail: email },
            });
          }
        }
        setShowingAnswer(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showingAnswer, questions.length, userAnswers, user, email, attempt]);

  useEffect(() => {
    const fetchQuizState = async () => {
      const storedIndex = localStorage.getItem('currentQuestionIndex');
      if (storedIndex !== null && attempt > 1) {
        setCurrentQuestionIndex(Number(storedIndex));
        currentQuestionIndexRef.current = Number(storedIndex);
      } else {
        setCurrentQuestionIndex(0);
        currentQuestionIndexRef.current = 0;
      }

      if (email) {
        const quizStateDoc = doc(db, 'quizState', email);
        const quizStateSnapshot = await getDoc(quizStateDoc);
        if (quizStateSnapshot.exists()) {
          const quizStateData = quizStateSnapshot.data();
          if (quizStateData.questions) {
            setQuestions(JSON.parse(quizStateData.questions));
          }
          if (quizStateData.currentQuestionIndex) {
            setCurrentQuestionIndex(quizStateData.currentQuestionIndex);
            currentQuestionIndexRef.current = quizStateData.currentQuestionIndex;
          } else {
            setCurrentQuestionIndex(0);
            currentQuestionIndexRef.current = 0;
          }
          if (quizStateData.userAnswers) {
            setUserAnswers(JSON.parse(quizStateData.userAnswers));
          }
        }
      }
    };
    fetchQuizState();
  }, [attempt]); // Corrigido: Adicionamos "attempt" como dependência, para que o efeito seja acionado quando uma nova tentativa é iniciada.

  const handleAnswerClick = async (answerIndex) => {
    const isCorrect = answerIndex === questions[currentQuestionIndexRef.current].correctAnswer;
    const newUserAnswers = [...userAnswers, isCorrect];
    setUserAnswers(newUserAnswers);
    setShowingAnswer(true);
    setCorrectAnswer(questions[currentQuestionIndexRef.current].correctAnswer);
    if (email) {
      await setDoc(doc(db, 'quizState', email), {
        questions: JSON.stringify(questions),
        currentQuestionIndex: currentQuestionIndexRef.current,
        userAnswers: JSON.stringify(newUserAnswers),
      });
    }
  };

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (questions.length === 0 || !questions[currentQuestionIndexRef.current]) {
    return <h1>Loading...</h1>;
  }

  if (showingAnswer && timeLeft === 0) {
  return (
    <div className={styles['quiz-container']}>
      <h1 className={`${styles.incorreto} ${styles.message}`}>Tempo Esgotado!</h1>
    </div>
  );
}


  const currentQuestion = questions[currentQuestionIndexRef.current];

  if (showingAnswer) {
  return (
    <div className={styles['quiz-container']}>
      <h1 className={`${userAnswers[userAnswers.length - 1] ? styles.correto : styles.incorreto} ${styles.message}`}>
        {userAnswers[userAnswers.length - 1] ? 'Correto!' : 'Incorreto!'}
      </h1>
    </div>
  );
}


  return (
    <div className={styles['quiz-container']}>
<Header /> {/* Use o componente de cabeçalho */}
      <h1 className={styles['quiz-title']}>Quiz</h1>
      <p className={styles['question-text']}>{currentQuestion.text}</p>
      <div className={styles['answer-container']}>
        {currentQuestion.answers.map((answer, index) => (
          <button
            className={styles['answer-button']}
            key={index}
            style={{ backgroundColor: currentQuestion.backgroundColors[index] }}
            onClick={() => handleAnswerClick(index)}
          >
            {answer}
          </button>
        ))}
      </div>
      <div className={styles['timer']}>Tempo restante: {timeLeft} segundos</div>
      <div className={styles['question-number']}>Pergunta: {currentQuestionIndexRef.current + 1}/15</div>
      <div className={styles['attempt-number']}>Tentativa: {attempt + 1}/3</div>


      <div className="footer text-center">
        <center>
          <p> ________________________ </p>
        </center>
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  );
};

export default Quiz;

