import React, { useState, useEffect, useContext } from 'react';
import { getFirestore, collection, getDocs, deleteDoc, doc, where, query } from '@firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import logoPanini from '../images/logo_panini.png';
import styles from './UserList.module.css';
import { AuthContextCB2024 } from './AuthContextCB2024';

function UserListCB2024() {
  const [participants, setParticipants] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5); // Defina a quantidade inicial de usuários por página
  const navigate = useNavigate();
  const { userCB2024 } = useContext(AuthContextCB2024);

  useEffect(() => {
    if (!userCB2024) {
      navigate('/cb2024/dsa_admin_login');
    } else {
      fetchData();
    }
  }, [userCB2024, navigate]);

  const fetchData = async () => {
    const db = getFirestore();
    const participantesRef = collection(db, 'participantes');
    const q = query(participantesRef, where("fromCB2024", "==", true));
    const data = await getDocs(q);
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    const usersToShow = data.docs.slice(startIndex, endIndex).map(doc => ({ ...doc.data(), id: doc.id }));
    setParticipants(usersToShow);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, usersPerPage]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredParticipants = participants.filter(participant =>
    participant.email.toLowerCase().includes(search.toLowerCase())
  );

  const handleDeleteUser = async (id) => {
    const db = getFirestore();
    await deleteDoc(doc(db, "participantes", id));
    const updatedParticipants = participants.filter(participant => participant.id !== id);
    setParticipants(updatedParticipants);
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <img src={logoPanini} alt="Logo Panini" className={styles.logo} />
      </div>
      <div className={styles['dashboard-content']}>
        <button onClick={() => navigate('/cb2024/dsa_admin')} className={styles.voltar}>Voltar ao Painel</button>
        <div className={styles['search-container']}>
          <div className={styles['search-text']}>Buscar Usuário</div>
          <div className={styles['search-box']}>
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              placeholder="Buscar participantes por email..."
              className={styles['search-input']}
            />
          </div>
        </div>
        <div className={styles['users-per-page']}>
      <span className={styles['users-per-page-text']}>Exibir por página:</span>
      <button
        className={`${styles['users-per-page-button']} ${usersPerPage === 5 ? styles.active : ''}`}
        onClick={() => setUsersPerPage(5)}
      >
        5
      </button>
      <button
        className={`${styles['users-per-page-button']} ${usersPerPage === 10 ? styles.active : ''}`}
        onClick={() => setUsersPerPage(10)}
      >
        10
      </button>
      <button
        className={`${styles['users-per-page-button']} ${usersPerPage === 20 ? styles.active : ''}`}
        onClick={() => setUsersPerPage(20)}
      >
        20
      </button>
    </div>
        {filteredParticipants.map(participant => (
          <div key={participant.id} className={styles['user-card']}>
            <Link to={`/cb2024/user-profile/${participant.id}`}>
              <h2>{participant.name}</h2>
            </Link>
            <p>{participant.email}</p>
            <hr className={styles['user-card-divider']} />
            <p>Código do cupom: {participant.couponCode}</p>
            <button onClick={() => handleDeleteUser(participant.id)} className={styles['delete-button']}>
              Deletar Usuário
            </button>
          </div>
        ))}
        <div className={styles['pagination']}>
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} className={styles['pagination-button']}>
            Anterior
          </button>
          <span>Página {currentPage}</span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={participants.length < usersPerPage} className={styles['pagination-button']}>
            Próxima
          </button>
        </div>
        <button onClick={() => navigate('/cb2024/dsa_admin')} className={styles.voltar}>Voltar ao Painel</button>
      </div>
    </div>
  );
}

export default UserListCB2024;




