import React from 'react';
import './PoliticaDeCookies.css';
import paniniLogo from '../images/logo_panini.png';
import comboLogo from '../images/COMBOLOGO.png';
import Header from './HeaderCB2024'; // Importe o componente de cabeçalho 



const PoliticaDeCookiesCB2024 = () => {
  return (
    <div>
      <Header /> {/* Use o componente de cabeçalho */}

      {/* Main Content */}
      <div className="politica-de-cookies-container">
        <div className="text-container">
   
        <h1 className="title">Política de Privacidade</h1>
        <h2 className="section-title">Resumo e Introdução</h2>
        <p>
         Este documento tem o objetivo de informar e estabelecer os critérios para coleta, tratamento, uso, disponibilização, armazenamento e descarte de dados pessoais dos usuários, de acordo com a legislação aplicável. Ao aceitar essa política de privacidade, você concorda expressamente com os termos aqui estabelecidos. Sempre que enviar informações por meio de formulários ou pela navegação do hotsite, a coleta, o uso e a divulgação de tais informações estarão autorizados. A Panini considera valor essencial a privacidade do usuário de seus serviços, e é de total interesse da empresa que seu público se mantenha informado sobre qualquer alteração ou novidade na política de privacidade e nos termos de uso – o que será feito pelo e-mail fornecido por você.
        </p>

<h2 className="section-title">Da Coleta de Dados</h2>

<p>A Panini não obriga o fornecimento de dados pessoais para que seu usuário navegue nas seções principais de seu site. No entanto, a aplicação deste hotsite é exclusiva para usuários registrados. Os dados coletados pela Panini podem ser de dois tipos: • Dados Pessoais e Informações Anônimas. Os dados pessoais consistem em informações requisitadas durante o cadastro participação do Quiz ou ainda de informações geradas pelo gerenciamento de cada relação. Informações anônimas são aquelas relacionadas ao uso do site, hábitos de navegação e dados de audiência em geral. Essas informações permanecem anônimas, sem a identificação do usuário, servindo para fins de métrica e estatísticas internas. Para mais informações, consulte nossa Política de Cookies.</p>
        </div>

        <div className="footer text-center">
          <p className="footer-text">UM PROJETO DE</p>
          <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
<footer className="footer">
  <div className="footer-links">
    <a className="footer-link" href="/cob/termos-e-condicoes">Termos e Condições</a>
    <span className="footer-link-separator">|</span>
    <a className="footer-link" href="/cob/politica-de-privacidade">Política de Privacidade</a>
  </div>
  
</footer>
          <p className="footer-text">© 2023 Digital Sport Academy</p>
        </div>
      </div>
    </div>
  );
};

export default PoliticaDeCookiesCB2024;



