import React, { useState, useEffect, useContext } from 'react'; // adicionado useContext
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { db, addCouponCB2024, getCouponsCB2024 } from '../firebase';
import styles from './CupomManager.module.css'; // Importando o CSS específico para o componente
import logoPanini from '../images/logo_panini.png';
import { AuthContextCB2024 } from './AuthContextCB2024'; // Isso deve apontar para onde o AuthContextCB2024 está definido

function CupomManagerCB2024() {
  const [file, setFile] = useState(null);
  const [manualCode, setManualCode] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [displayedCoupons, setDisplayedCoupons] = useState([]);
  const [search, setSearch] = useState('');
  const [numToGenerate, setNumToGenerate] = useState('');

  const navigate = useNavigate();
  const { userCB2024 } = useContext(AuthContextCB2024); // usando useContext para acessar o AuthContextCB2024

  useEffect(() => {
    if (!userCB2024) {
      navigate('/cb2024/dsa_admin_login');
    }
  }, [userCB2024, navigate]);


  const handleBack = () => {
    navigate(-1);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleManualCodeChange = (e) => {
    setManualCode(e.target.value);
  };

  const handleManualCodeSubmit = async (e) => {
    e.preventDefault();
    if (manualCode) {
      await addCouponCB2024({ code: manualCode, used: false, from: 'cb2024' });
      setManualCode("");
      fetchCoupons();
    }
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const lines = event.target.result.split('\n');
        for (let line of lines) {
          await addCouponCB2024({ code: line.trim(), used: false, from: 'cb2024' });
        }
        setFile(null);
        fetchCoupons();
      };
      reader.readAsText(file);
    }
  };

  const fetchCoupons = async () => {
    const fetchedCoupons = await getCouponsCB2024();
    setCoupons(fetchedCoupons);
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  useEffect(() => {
    if (search === '') {
      setDisplayedCoupons([]);
    } else {
      const searchResults = coupons.filter(coupon => coupon.code.includes(search));
      setDisplayedCoupons(searchResults);
    }
  }, [search, coupons]);

  const totalCoupons = coupons.length;
  const usedCoupons = coupons.filter(coupon => coupon.used).length;
  const availableCoupons = totalCoupons - usedCoupons;

  const handleGenerate = async () => {
    for (let i = 0; i < numToGenerate; i++) {
      const code = "DSA" + uuidv4();
      await addCouponCB2024({ code, used: false, from: 'cb2024' });
    }
    setNumToGenerate('');
    fetchCoupons();
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredCoupons = coupons.filter(coupon =>
    coupon.code.toLowerCase().includes(search.toLowerCase())
  );


return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <img src={logoPanini} alt="Logo Panini" className={styles.logo} />
      </div>
      <div className={styles['dashboard-content']}>
        <div className={styles['content-box']}>
          <form onSubmit={handleManualCodeSubmit}>
            <div className={styles['form-row']}>
              <label>
                Inserir código manualmente:
                <input type="text" value={manualCode} onChange={handleManualCodeChange} />
              </label>
              <button className={`${styles.button} ${styles.manual}`} type="submit">Enviar</button>
            </div>
          </form>

          <form onSubmit={handleFileSubmit}>
            <div className={styles['form-row']}>
              <label>
                Upload de arquivo .txt:
                <input type="file" accept=".txt" onChange={handleFileChange} />
              </label>
              <button className={`${styles.button} ${styles.upload}`} type="submit">Enviar</button>
            </div>
          </form>

          <div>
            <input type="number" min="1" placeholder="Gerar cupons" value={numToGenerate} onChange={e => setNumToGenerate(e.target.value)} />
            <button className={`${styles.button} ${styles.generate}`} onClick={handleGenerate}>Gerar</button>
          </div>

          <div className={styles['coupon-info']}>
            <p>Total de cupons: {totalCoupons}</p>
            <p>Cupons utilizados: {usedCoupons}</p>
            <p>Cupons disponíveis: {availableCoupons}</p>
            {totalCoupons > 1000 && <p style={{ color: 'yellow'}}>Limite de 1000 códigos excedido, verifique seus códigos.</p>}
          </div>

          <button onClick={handleBack} className={`${styles.button} ${styles.voltar}`}>Voltar</button>
        </div>

        <div className={styles.searchBox}>
          <input type="text" value={search} onChange={handleSearchChange} placeholder="Buscar cupons..." />
          {search && filteredCoupons.map((coupon, index) => (
  <div key={index} className={styles["question-text"]}>
    <h2>{coupon.code}</h2>
    <p>
      <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: coupon.used ? 'green' : 'red', marginRight: '5px' }}></span>
      {coupon.used ? `Utilizado por ${coupon.usedBy ? coupon.usedBy : 'Sem utilização'}` : 'Sem utilização'}
    </p>
  </div>
))}
        </div>
      </div>
    </div>
);

}

export default CupomManagerCB2024;
