import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Home, ExternalLink, User, Menu, X, LogOut } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import paniniLogo from '../images/logo_panini.png';
import paniniMobileLogo from '../images/logomobile.png';
import './Header.css';

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [menuOpen, setMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');

  const toggleMenu = () => setMenuOpen(prevState => !prevState);

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    localStorage.setItem('isAuthenticated', 'false');
    setIsAuthenticated(false);
    // Limpar outros dados de cache, armazenamento, etc. relacionados ao email aqui
    window.location.href = '/';
  };

  useEffect(() => {
    // Lógica adicional relacionada à autenticação, se necessário
  }, [isAuthenticated]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.mobile-menu')) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  return (
    <header className="header fixed-header">
      <div className="container">
        <div className="logo-links-container">
          <Link to="/" className="logo-link">
            <img src={isMobile ? paniniMobileLogo : paniniLogo} alt="Panini Logo" className="panini-logo" />
          </Link>
          {isMobile ? (
            <button className="menu-button" onClick={toggleMenu}>
              <Menu size={40} />
            </button>
          ) : (
            <nav className="header-navigation">
              <Link to="/" className="header-link">
                <Home size={17} />
                <span className="link-text">Página Inicial</span>
              </Link>
              <a
                href="https://paninihelp.zendesk.com/hc/pt-br"
                target="_blank"
                rel="noreferrer"
                className="header-link"
              >
                <ExternalLink size={17} />
                <span className="link-text">Atendimento ao Cliente</span>
              </a>
              <Link to="/cob/minha-conta" className="header-link">
                <User size={17} />
                <span className="link-text">Perfil</span>
              </Link>
              {isAuthenticated && (
  <Link to="/" className="header-link" onClick={handleLogout}>
    <LogOut size={17} />
    <span className="link-text-icon">Sair</span>
  </Link>
)}
            </nav>
          )}
        </div>
      </div>
      {isMobile && (
        <div className={`container ${menuOpen ? 'open' : ''}`}>
          <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
            <Link to="/" className="mobile-menu-link">
              <Home size={17} />
              <span className="link-text">Página Inicial</span>
            </Link>
            <a
              href="https://paninihelp.zendesk.com/hc/pt-br"
              target="_blank"
              rel="noreferrer"
              className="mobile-menu-link"
            >
              <ExternalLink size={17} />
              <span className="link-text">Atendimento ao Cliente</span>
            </a>
            <Link to="/cob/minha-conta" className="mobile-menu-link">
              <User size={17} />
              <span className="link-text">Perfil</span>
            </Link>
            {isAuthenticated && (
  <Link to="/" className="header-link" onClick={handleLogout}>
    <LogOut size={17} />
    <span className="link-text-icon">Sair</span>
  </Link>
)}
            <button className="close-menu-button" onClick={toggleMenu}>
              <X size={20} />
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;


