import React from 'react';
import { Link } from 'react-router-dom';

function SideNav() {
  return (
    <div className="side-nav">
      <ul>
        <li><Link to="/cob/dsa_admin/users">Usuários</Link></li>
        <li><Link to="/cob/dsa_admin/cupons">Gerenciador de Cupons</Link></li>
        <li><Link to="/cob/dsa_admin/questions">Editor de Questões</Link></li>
        <li><Link to="/cob/dsa_admin/exportar-participantes">Gerador de Relatórios</Link></li>
      </ul>
    </div>
  );
}

export default SideNav;

