import React, { useState, useContext } from 'react';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth } from '../firebase';
import { AuthContextCB2024 } from './AuthContextCB2024';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css'; // Importando os estilos do módulo
import logoPanini from '../images/logo_panini.png'; // Importando o logo da Panini

function LoginCB2024() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setuserCB2024, adminEmail } = useContext(AuthContextCB2024);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setuserCB2024(userCredential.user);

      // Check if the user is an admin
      if (userCredential.user.email === adminEmail) {
        console.log("User is admin");
        navigate('/cb2024/dsa_admin');
      } else {
        alert("Acesso negado. Este portal é apenas para administradores.");
      }

    } catch (error) {
      alert("Falha ao logar, verifique suas credenciais e tente novamente.");
      console.error("Error logging in: ", error);
    }
  }

  return (
    <div className={styles.formContainer}> {/* Utilizando a classe formContainer para centralizar */}
      <img src={logoPanini} alt="Logo Panini" width="200px" /> {/* Logo da Panini */}
      <h1 className={styles.title}>DSA - Admin</h1> {/* Utilizando a classe title para o título */}
      <form className={styles['login-form']} onSubmit={handleSubmit}>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <button className={styles['login-button']} type="submit">Logar</button> {/* Adicionando a classe ao botão */}
      </form>

    </div>
  );
}

export default LoginCB2024;

