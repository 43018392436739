import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './TryAgain.module.css';
import logo from '../images/logo_panini.png';
import { QuizContext } from './QuizContext';
import Header from './Header';

const TryAgain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userCorrectAnswers = location.state?.correctAnswers || [];

  const { remainingAttempts } = useContext(QuizContext); // Obtendo remainingAttempts do contexto do Quiz

  const handleRestartQuiz = () => {
    navigate('/cob/quiz');
  };

  const handleExitQuiz = () => {
    navigate('/');
  };

  return (
<>
      <Header />
    <div className={styles['tryagain-container']}>
      <img src={logo} alt="Panini Logo" className={styles.logo}/>
      <div>
        <h1 className={styles.title}>Não Passou.</h1>
        <h2 className={styles.subtitle}>Tente novamente!</h2>
      </div>
      <p className={styles.text}>
        Parabéns por ter concluído o teste e obrigado por participar! <br/>
        <br/>
        Você respondeu corretamente a <span className={styles.redText}><strong>{userCorrectAnswers.filter(answer => answer).length}</strong></span> perguntas de 15.<br/><br/>
        Infelizmente, você não passou no teste e não pode acessar a "Digital Sport Academy" powered by Panini.<br/>
        <br/>
        Mas ainda não acabou... <br/>
        Você tem mais <span className={styles.redText}><strong>{remainingAttempts}</strong></span> {remainingAttempts > 1 ? 'tentativas' : 'tentativa'}.<br/>
        <br/>
        Boa sorte!<br/>
        Equipe Panini
      </p>
      <button className={styles.button} onClick={handleRestartQuiz}>Reiniciar Quiz</button>
      <button className={styles.exitButton} onClick={handleExitQuiz}>Sair</button>
    </div>
 </>
  );
};

export default TryAgain;





