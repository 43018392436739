// UserProfile.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from '@firebase/firestore';

function UserProfile() {
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  
  useEffect(() => {
    const fetchUser = async () => {
      const db = getFirestore();
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setUser(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchUser();
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <p>Data de conclusão do Quiz: {user.quizCompletionDate}</p>
      <p>Código do cupom: {user.couponCode}</p>
      {/* Aqui você pode adicionar mais informações sobre o usuário e suas respostas ao quiz */}
    </div>
  );
}

export default UserProfile;

