import React, { createContext, useState } from 'react';
import { db } from '../firebase';

const QuizContextCB2024 = createContext();

const QuizProviderCB2024 = ({ children }) => {
  const [attemptCB2024, setattemptCB2024] = useState(1);
  const [remainingattemptCB2024s, setRemainingattemptCB2024s] = useState(3);
  const [correctAnswersCB2024, setcorrectAnswersCB2024] = useState([]);
  const [coupon, setCoupon] = useState(null);

  const getUnusedCoupon = async () => {
    const couponRef = db.collection('coupons');
    const snapshot = await couponRef
      .where('used', '==', false)
      .where('from', '==', 'cb2024')
      .limit(1)
      .get();
    if (snapshot.empty) {
      console.log('Não há cupons não utilizados disponíveis.');
      return;
    }

    let coupon = null;
    snapshot.forEach(doc => {
      coupon = { id: doc.id, ...doc.data() };
    });

    return coupon;
  }

  const updateCouponStatus = async (coupon) => {
    const couponRef = db.collection('coupons').doc(coupon.id);
    return couponRef.update({ used: true, from: 'cb2024' });
  }

  const handleQuizFinish = async (ispassedCB2024) => {
    setattemptCB2024(attemptCB2024 + 1);
    setRemainingattemptCB2024s(remainingattemptCB2024s - 1);
    if (ispassedCB2024) {
      const newCoupon = await getUnusedCoupon();
      if (newCoupon) {
        await updateCouponStatus(newCoupon);
        setCoupon(newCoupon);
      }
    }
  }

  const startNewQuiz = () => {
    setcorrectAnswersCB2024([]);
  }

  return (
    <QuizContextCB2024.Provider value={{ attemptCB2024, remainingattemptCB2024s, setattemptCB2024, correctAnswersCB2024, setcorrectAnswersCB2024, handleQuizFinish, coupon, startNewQuiz }}>
      {children}
    </QuizContextCB2024.Provider>
  );
};

export { QuizContextCB2024, QuizProviderCB2024 };



