import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, updateUserCB2024, getUserByEmailCB2024, updateParticipantCB2024 } from '../firebase';
import { collection, getDocs, query, setDoc, doc, getDoc } from 'firebase/firestore';
import { QuizContextCB2024 } from './QuizContextCB2024';
import { AuthContextCB2024 } from './AuthContextCB2024';
import styles from './Quiz.module.css';
import comboLogo from '../images/COMBOLOGO.png';
import Header from './HeaderCB2024'; // Importe o componente de cabeçalho

const QuizCB2024 = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, settimeLeft] = useState(() => {
    const savedTime = localStorage.getItem('timeLeftCB2024');
    return savedTime ? Number(savedTime) : 30;
  });
  const { attemptCB2024, setattemptCB2024, handleQuizFinish } = useContext(QuizContextCB2024);
  const { userCB2024, loading } = useContext(AuthContextCB2024);
  const [showingAnswer, setShowingAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const email = window.localStorage.getItem('userEmail');
  const colors = ['#ed254e', '#ffc857', '#ff82a9', '#aad159', '#00a19a'];

  const currentQuestionIndexRef = useRef(0);

  const setcurrentQuestionIndex = (index) => {
    currentQuestionIndexRef.current = index;
    localStorage.setItem('currentQuestionIndexCB2024', index);
  };

  useEffect(() => {
    if (!email) {
      navigate('/cb2024/register');
    } else {
      getUserByEmailCB2024(email)
        .then((userCB2024) => {
          if (userCB2024) {
            userCB2024.attemptCB2024s = userCB2024.attemptCB2024s || 0;
            setattemptCB2024(userCB2024.attemptCB2024s);
            if (userCB2024.passedCB2024) {
              navigate('/cb2024/congratulations');
            } else if (userCB2024.attemptCB2024s >= 3) {
              navigate('/cb2024/nao-passou');
            }
          } else {
            console.error(`User with email ${email} not found in Firestore.`);
          }
        })
        .catch((err) => {
          console.error(`Failed to fetch user data from Firestore for email ${email}`, err);
        });
    }
  }, [email, navigate, setattemptCB2024]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const fetchQuestions = async () => {
    let selectedQuestions = JSON.parse(localStorage.getItem('selectedQuestionsCB2024'));
    if (selectedQuestions === null) {
      const questionsCollection = query(collection(db, 'questions'));
      const questionSnapshot = await getDocs(questionsCollection);
      const questionList = questionSnapshot.docs.map((doc) => {
        let questionData = doc.data();
        questionData.correctAnswer = Number(questionData.correctAnswer) - 1;
        shuffleArray(colors);
        questionData.backgroundColors = [...colors];
        return questionData;
      });

      selectedQuestions = [];
      while (selectedQuestions.length < 15) {
        let question = questionList[Math.floor(Math.random() * questionList.length)];
        if (!selectedQuestions.includes(question)) {
          selectedQuestions.push(question);
        }
      }
      localStorage.setItem('selectedQuestionsCB2024', JSON.stringify(selectedQuestions));
    }
    setQuestions(selectedQuestions);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      settimeLeft((prevtimeLeft) => {
        if (prevtimeLeft > 0) {
          const newtimeLeft = prevtimeLeft - 1;
          localStorage.setItem('timeLeftCB2024', newtimeLeft); // Salve o tempo restante no localStorage
          return newtimeLeft;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);



useEffect(() => {
    fetchQuestions();
  }, []);

 

  useEffect(() => {
    if (timeLeft === 0) {
      setShowingAnswer(true);
      setCorrectAnswer(questions[currentQuestionIndexRef.current].correctAnswer);
    }
  }, [timeLeft, questions]);

  useEffect(() => {
    if (showingAnswer) {
      const timer = setTimeout(async () => {
        if (currentQuestionIndexRef.current < questions.length - 1) {
          setcurrentQuestionIndex(currentQuestionIndexRef.current + 1);
          settimeLeft(30);
          if (email) {
            await setDoc(doc(db, 'quizStateCB2024', email), {
              questions: JSON.stringify(questions),
              currentQuestionIndex: currentQuestionIndexRef.current,
              userAnswers: JSON.stringify(userAnswers),
            });
          }
        } else {
          handleQuizFinish();

          const correctAnswersCB2024Count = userAnswers.filter((answer) => answer).length;
          const passedCB2024 = correctAnswersCB2024Count >= 12;
          const lastPlayedDateCB2024 = new Date().toISOString();

          if (email) {
            await updateUserCB2024(email, { attemptCB2024s: attemptCB2024 + 1, passedCB2024 });
            const data = {
              attemptCB2024: attemptCB2024 + 1,
              correctAnswersCB2024: correctAnswersCB2024Count,
              passedCB2024,
              fromCB2024: true,
              lastPlayedDateCB2024,
            };
            await updateParticipantCB2024(email, data);
          }

          localStorage.removeItem('selectedQuestionsCB2024');
          if (email) {
            await setDoc(doc(db, 'quizStateCB2024', email), {});
          }
          if (!passedCB2024) {
            if (attemptCB2024 >= 2) {
              navigate('/cb2024/nao-passou');
            } else {
              setattemptCB2024((prevattemptCB2024) => prevattemptCB2024 + 1);
              setUserAnswers([]);
              setcurrentQuestionIndex(0);
              navigate('/cb2024/try-again', { state: { correctAnswersCB2024: userAnswers } });
            }
          } else {
            navigate('/cb2024/congratulations', {
              state: { correctAnswersCB2024: userAnswers, userEmail: email },
            });
          }
        }
        setShowingAnswer(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showingAnswer, questions.length, userAnswers, userCB2024, email, attemptCB2024]);

  useEffect(() => {
    const fetchquizStateCB2024 = async () => {
      const storedIndex = localStorage.getItem('currentQuestionIndexCB2024');
      if (storedIndex !== null && attemptCB2024 > 1) {
        setcurrentQuestionIndex(Number(storedIndex));
        currentQuestionIndexRef.current = Number(storedIndex);
      } else {
        setcurrentQuestionIndex(0);
        currentQuestionIndexRef.current = 0;
      }

      if (email) {
        const quizStateCB2024Doc = doc(db, 'quizStateCB2024', email);
        const quizStateCB2024Snapshot = await getDoc(quizStateCB2024Doc);
        if (quizStateCB2024Snapshot.exists()) {
          const quizStateCB2024Data = quizStateCB2024Snapshot.data();
          if (quizStateCB2024Data.questions) {
            setQuestions(JSON.parse(quizStateCB2024Data.questions));
          }
          if (quizStateCB2024Data.currentQuestionIndex) {
            setcurrentQuestionIndex(quizStateCB2024Data.currentQuestionIndex);
            currentQuestionIndexRef.current = quizStateCB2024Data.currentQuestionIndex;
          } else {
            setcurrentQuestionIndex(0);
            currentQuestionIndexRef.current = 0;
          }
          if (quizStateCB2024Data.userAnswers) {
            setUserAnswers(JSON.parse(quizStateCB2024Data.userAnswers));
          }
        }
      }
    };
    fetchquizStateCB2024();
  }, [attemptCB2024]); // Corrigido: Adicionamos "attemptCB2024" como dependência, para que o efeito seja acionado quando uma nova tentativa é iniciada.

  const handleAnswerClick = async (answerIndex) => {
    const isCorrect = answerIndex === questions[currentQuestionIndexRef.current].correctAnswer;
    const newUserAnswers = [...userAnswers, isCorrect];
    setUserAnswers(newUserAnswers);
    setShowingAnswer(true);
    setCorrectAnswer(questions[currentQuestionIndexRef.current].correctAnswer);
    if (email) {
      await setDoc(doc(db, 'quizStateCB2024', email), {
        questions: JSON.stringify(questions),
        currentQuestionIndex: currentQuestionIndexRef.current,
        userAnswers: JSON.stringify(newUserAnswers),
      });
    }
  };

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (questions.length === 0 || !questions[currentQuestionIndexRef.current]) {
    return <h1>Loading...</h1>;
  }

  if (showingAnswer && timeLeft === 0) {
  return (
    <div className={styles['quiz-container']}>
      <h1 className={`${styles.incorreto} ${styles.message}`}>Tempo Esgotado!</h1>
    </div>
  );
}


  const currentQuestion = questions[currentQuestionIndexRef.current];

  if (showingAnswer) {
  return (
    <div className={styles['quiz-container']}>
      <h1 className={`${userAnswers[userAnswers.length - 1] ? styles.correto : styles.incorreto} ${styles.message}`}>
        {userAnswers[userAnswers.length - 1] ? 'Correto!' : 'Incorreto!'}
      </h1>
    </div>
  );
}


  return (
    <div className={styles['quiz-container']}>
<Header /> {/* Use o componente de cabeçalho */}
      <h1 className={styles['quiz-title']}>Quiz</h1>
      <p className={styles['question-text']}>{currentQuestion.text}</p>
      <div className={styles['answer-container']}>
        {currentQuestion.answers.map((answer, index) => (
          <button
            className={styles['answer-button']}
            key={index}
            style={{ backgroundColor: currentQuestion.backgroundColors[index] }}
            onClick={() => handleAnswerClick(index)}
          >
            {answer}
          </button>
        ))}
      </div>
      <div className={styles['timer']}>Tempo restante: {timeLeft} segundos</div>
      <div className={styles['question-number']}>Pergunta: {currentQuestionIndexRef.current + 1}/15</div>
      <div className={styles['attemptCB2024-number']}>Tentativa: {attemptCB2024 + 1}/3</div>


      <div className="footer text-center">
        <center>
          <p> ________________________ </p>
        </center>
        <p className="footer-text">UM PROJETO DE</p>
        <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
        <p className="footer-text">© 2023 Digital Sport Academy</p>
      </div>
    </div>
  );
};

export default QuizCB2024;