import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import SideNav from './SideNav'; // Importando o componente SideNav
import logoPanini from '../images/logo_panini.png'; // Importando a imagem do logo da Panini
import './Dashboard.css';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

function Dashboard() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [participantCount, setParticipantCount] = useState(0);
  const [couponCount, setCouponCount] = useState(0);

  useEffect(() => {
    if (!user) {
      navigate('/cob/dsa_admin_login');
    }

    const fetchData = async () => {
      const participantsSnapshot = collection(db, "participantes");
      const q = query(participantsSnapshot, where("fromCOB", "==", true));
      const snapshot = await getDocs(q);
      setParticipantCount(snapshot.size);

      const usedCouponsQuery = query(collection(db, "coupons"), where("used", "==", true), where("from", "==", "cob"));
      const usedCouponsSnapshot = await getDocs(usedCouponsQuery);
      setCouponCount(usedCouponsSnapshot.size);
    };

    fetchData();
  }, [user, navigate]);

  return (
    <div className="dashboard">
          <div className="header">
        <img src={logoPanini} alt="Logo Panini" className="logo" />
        
      </div>
      <div className="dashboard-content">
        <div className="dashboard-left">
          <SideNav />
        </div>
        <div className="dashboard-right">
          <p>Participantes do Quiz: {participantCount}</p>
          <p>Cupons Distribuídos: {couponCount}</p>
          <button onClick={() => navigate('/cob/dsa_admin_login')}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;


