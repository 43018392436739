import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterButton.css';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';



const RegisterButton = () => {
    const [coupon, setCoupon] = useState(1);
    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
              const couponsQuery = query(collection(db, "coupons"), where("used", "==", false), where("from", "==", "cob"));              const couponSnapshot = await getDocs(couponsQuery);
      
              if (couponSnapshot.empty) {
                setCoupon(null)
              }
            } catch (error) {
              console.error("An error occurred while fetching the data:", error);
            }
          }
          fetchData();
    }, [])

    function handleClick() {
        navigate("/cob/register");
    }

    return (
        <div className="black-section text-center">
            {coupon ? (
            <div style={{ textAlign: 'center' }}>
                <h2 className="white-text">IR PARA O TESTE DE ADMISSÃO</h2>
                <button type="button" className="register-button" onClick={handleClick}>
                    JOGAR
                </button>
            </div>
        ) : (<h2 className="white-text">DESCULPE, ESSA CAMPANHA FOI ENCERRADA.</h2>)}
        </div>
    );
};

export default RegisterButton;

