import React from 'react';
import './PoliticaDeCookies.css';
import paniniLogo from '../images/logo_panini.png';
import comboLogo from '../images/COMBOLOGO.png';
import Header from './HeaderCB2024'; // Importe o componente de cabeçalho 



const PoliticaDeCookiesCB2024 = () => {
  return (
    <div>
      <Header /> {/* Use o componente de cabeçalho */}

      {/* Main Content */}
      <div className="politica-de-cookies-container">
        <div className="text-container">
   
        <h1 className="title">Termos e Condições</h1>
        <h2 className="section-title">Condições gerais para uso do Hotsite</h2>
        <p>
         Aconselhamos você a ler cuidadosamente estes termos e condições de uso antes de navegar e usar os serviços deste site. Navegando e/ou usando os serviços desta aplicação, você aceita completamente estes Termos e Condições de Uso.
        </p>

<h2 className="section-title">Uso do conteúdo do Site</h2>

<p>O Hotsite é propriedade da Panini Brasil. - Acionista único (referido posteriormente como Panini). É proibida a cópia, reprodução, publicação, postagem ou distribuição por outros meios ou em outros sites e aplicações retirados deste site. O uso não autorizado de textos ou imagens, incluindo o uso parcial, será legalmente considerado como uma violação dos direitos autorais e outros direitos de propriedade. Ao acessar este site, o usuário declara ter lido, compreendido e aceitado as condições de uso; declara, para eventuais fins, observar todas as leis e regulamentos aplicáveis. Se você não aceitou completamente as condições de uso abaixo, não use esse site. Este site contém aviso de propriedade e informações de copyright, os termos dos quais devem ser observados e seguidos. As informações providas neste site podem conter imprecisões técnicas ou erros tipográficos pelos quais a Panini não pode ser considerada responsável. As informações podem ser alteradas ou atualizadas sem qualquer aviso. A Panini se reserva o direito de fazer mudanças e/ou melhorias, a qualquer momento sem nenhum aviso, nos produtos, serviços e/ou procedimentos deste site. Além disso, a Panini tem o direito de editar o site ou deletar conteúdo ou recursos de qualquer forma, sob quaisquer bases ou sem razão. A Panini não assume responsabilidade da precisão das informações fornecidas, o uso delas é de sua própria conta e risco. Além disso, por fornecer esse tipo de informação, a Panini não garante nenhuma licença de copyrights, patentes ou outro direito de propriedade intelectual. As informações publicadas pela Panini nesse site podem conter direta ou indiretamente referências a produtos cujos lançamentos não são anunciados ou que podem não estar disponíveis em seu país.</p>
        </div>

        <div className="footer text-center">
          <p className="footer-text">UM PROJETO DE</p>
          <img src={comboLogo} alt="Combo Logo" className="combo-logo" />
<footer className="footer">
  <div className="footer-links">
    <a className="footer-link" href="/cob/termos-e-condicoes">Termos e Condições</a>
    <span className="footer-link-separator">|</span>
    <a className="footer-link" href="/cob/politica-de-privacidade">Política de Privacidade</a>
  </div>
  
</footer>
          <p className="footer-text">© 2023 Digital Sport Academy</p>
        </div>
      </div>
    </div>
  );
};

export default PoliticaDeCookiesCB2024;



